import React, { Component } from 'react';
import * as actionTypes from '../../store/action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
    render() {
        const currentPath = window.location.pathname;
        const routeProfile = () => {

        }
        return (
            <aside className="left-sidebar bg-sidebar">
                <div id="sidebar" className="sidebar sidebar-with-footer">
                    <div className="app-brand">
                        <a href="/">
                            <svg
                                className="brand-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="xMidYMid"
                                width="30"
                                height="33"
                                viewBox="0 0 30 33"
                            >
                                <g fill="none" fillRule="evenodd">
                                    <path
                                        className="logo-fill-blue"
                                        fill="#7DBCFF"
                                        d="M0 4v25l8 4V0zM22 4v25l8 4V0z"
                                    />
                                    <path className="logo-fill-white" fill="#FFF" d="M11 4v25l8 4V0z" />
                                </g>
                            </svg>
                            <span className="brand-name">Pentagon Dashboard</span>
                        </a>
                    </div>
                    <div className="sidebar-scrollbar">

                        <ul className="nav sidebar-inner" id="sidebar-menu">
                            <li className={this.props.hasPages || this.props.hasChart || currentPath.includes('profile') || currentPath.includes('invoice') || currentPath.includes('error') ? "" : "active"} onClick={this.props.dashboard}>
                                <Link className="sidenav-item-link" to="/" data-toggle="collapse" data-target="#dashboard"
                                    aria-expanded="false" aria-controls="dashboard">
                                    <i className="mdi mdi-view-dashboard-outline"></i>
                                    <span className="nav-text">Dashboard</span>
                                </Link>
                            </li>

                            {/* <li >
                                <Link to="/profile" className="sidenav-item-link">
                                    <i className="mdi mdi-account"></i>
                                    <span className="nav-text">Profile</span>
                                </Link>
                            </li> */}




                            <li className={this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"} onClick={this.props.product}>
                                <a className={this.props.hasSubAuth ? "sidenav-item-link" : "sidenav-item-link collapsed"} href="javascript:void(0)" data-toggle="collapse" data-target="#products"
                                    aria-expanded="false" aria-controls="products">
                                    <i className="mdi mdi-image-filter-none"></i>
                                    <span className="nav-text">HomePage</span> <b className="caret"></b>
                                </a>
                                <ul className={this.props.hasSubAuth ? "collapse show" : "collapse"} id="products"
                                    data-parent="#sidebar-menu">
                                    <div className="sub-menu">
                                    <li>
                                            <Link className="sidenav-item-link" to="/HeaderImages">
                                                <span className="nav-text">Main Images</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/Settings">
                                                <span className="nav-text">Page Settings</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/metainformation">
                                                <span className="nav-text">Meta Info</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/contentpage">
                                                <span className="nav-text">Content Page</span>
                                            </Link>
                                        </li>
                                       
                                    </div>
                                </ul>
                            </li>







                            <li className={this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"} onClick={this.props.addCategory}>
                                <a className={this.props.hasSubAuth ? "sidenav-item-link" : "sidenav-item-link collapsed"} href="javascript:void(0)" data-toggle="collapse" data-target="#addCategory"
                                    aria-expanded="false" aria-controls="addCategory">
                                    <i className="mdi mdi-plus"></i>
                                    <span className="nav-text">NavBar</span> <b className="caret"></b>
                                </a>
                                <ul className={this.props.hasSubAuth ? "collapse show" : "collapse"} id="addCategory"
                                    data-parent="#sidebar-menu">
                                    <div className="sub-menu">
                                       
                                        <li>
                                            <Link className="sidenav-item-link" to="/Industries">
                                                <span className="nav-text">Industries</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/Materials">
                                                <span className="nav-text">Materials</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/ShapeStyle">
                                                <span className="nav-text">Shapes/Style</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/LabelOther">
                                                <span className="nav-text">Label/Sticker</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/specifications">
                                                <span className="nav-text">Specifications</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/printingmethod">
                                                <span className="nav-text">Printing Method</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/inkuse">
                                                <span className="nav-text">Ink Type</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/finishing">
                                                <span className="nav-text">Finishing</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sidenav-item-link" to="/addons">
                                                <span className="nav-text">Add Ons</span>
                                            </Link>
                                        </li>

                                    </div>
                                </ul>
                            </li>







                            <li className={this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"} onClick={this.props.charts}>
                                <a className={this.props.hasSubAuth ? "sidenav-item-link" : "sidenav-item-link collapsed"} href="javascript:void(0)" data-toggle="collapse" data-target="#orders"
                                    aria-expanded="false" aria-controls="orders">
                                    <i className="mdi mdi-chart-pie"></i>
                                    <span className="nav-text">Products</span> <b className="caret"></b>
                                </a>
                                <ul className={this.props.hasSubAuth ? "collapse show" : "collapse"} id="orders"
                                    data-parent="#sidebar-menu">
                                    <div className="sub-menu">
                                    <li>
                                            <Link className="sidenav-item-link" to="/AddProducts">
                                                <span className="nav-text">Add Product</span>
                                            </Link>
                                        </li> 
                                    </div>
                                </ul>
                            </li>

                            <li className={this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"} onClick={this.props.reports}>
                                <a className={this.props.hasSubAuth ? "sidenav-item-link" : "sidenav-item-link collapsed"} href="javascript:void(0)" data-toggle="collapse" data-target="#reports"
                                    aria-expanded="false" aria-controls="reports">
                                    <i className="mdi mdi-file-chart"></i>
                                    <span className="nav-text">FAQs</span> <b className="caret"></b>
                                </a>
                                <ul className={this.props.hasSubAuth ? "collapse show" : "collapse"} id="reports"
                                    data-parent="#sidebar-menu">
                                    <div className="sub-menu">
                                        {/* Add items for the "Reports" section */}
                                        <li>
                                            <Link className="sidenav-item-link" to="/AddFaq">
                                                <span className="nav-text">Add FAQs</span>
                                            </Link>
                                        </li>
                                    </div>
                                </ul>
                            </li>

                            <li className={this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"} onClick={this.props.dealsAndOffers}>
                                <a className={this.props.hasSubAuth ? "sidenav-item-link" : "sidenav-item-link collapsed"} href="javascript:void(0)" data-toggle="collapse" data-target="#dealsAndOffers"
                                    aria-expanded="false" aria-controls="dealsAndOffers">
                                    <i className="mdi mdi-gift"></i>
                                    <span className="nav-text">Quotes</span> <b className="caret"></b>
                                </a>
                                <ul className={this.props.hasSubAuth ? "collapse show" : "collapse"} id="dealsAndOffers"
                                    data-parent="#sidebar-menu">
                                    <div className="sub-menu">
                                        {/* Add items for the "Deals & Offers" section */}
                                        <li>
                                            <Link className="sidenav-item-link" to="/AllQuotes">
                                                <span className="nav-text">All Quotes</span>
                                            </Link>
                                        </li> 
                                    </div>
                                </ul>
                            </li>




                        </ul>
                    </div>
                </div>
            </aside >
        );
    }
}
const mapStateToProps = state => {
    return {
        'hasChart': state.hasChart,
        'hasPages': state.hasPages,
        'hasSubAuth': state.hasSubAuth,
        'hasProfile': state.hasProfile,
        'hasOtherPages': state.hasOtherPages
    }
}
const mapDispatchToProps = dispatch => {
    return {
        profiles: () => dispatch({ type: actionTypes.HAS_PROFILE }),
        charts: () => dispatch({ type: actionTypes.HAS_CHART }),
        pages: () => dispatch({ type: actionTypes.HAS_PAGES }),
        auths: () => dispatch({ type: actionTypes.HAS_SUB_AUTH }),
        others: () => dispatch({ type: actionTypes.HAS_OTHER_PAGES }),
        dashboard: () => dispatch({ type: actionTypes.DASHBOARD }),
        invoice: () => dispatch({ type: actionTypes.INVOICE_MENU })
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);