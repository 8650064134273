import React, { useState, useEffect } from "react";
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import ClearIcon from "@mui/icons-material/Clear";
import { getAsset } from "../../utils/helper";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  CardMedia,
  DialogContentText,
  Dialog,
  DialogActions,
  IconButton,
  Modal,
} from "@mui/material";
import { useDropzone } from "react-dropzone";


// Styling for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PrintingMethod = () => {
  const currentPath = window.location.pathname;

  // State for modal visibility
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setTitle("");
    setDescription("");
    setHeading("");
    setEditImageStore([])
    setEditedItemId("")
    setEditImageShow(false)
    setEditOptionOpen(false)
  };
  const handleClose = () => {
    setOpen(false);
    clearAllHooks();
  };
  const [image, setImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [editImageShow, setEditImageShow] = useState(false);
  const [editImageStore, setEditImageStore] = useState([]);
  const [editedItemId, setEditedItemId] = useState("");
  
  const [editOptionOpen, setEditOptionOpen] = useState(false);
  
  // State for heading and description fields
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");

  // State for title field in modal
  const [title, setTitle] = useState("");

  // State to store data from DB
  const [printingMethods, setPrintingMethods] = useState([]);
  //  open delete modallll
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
  const [selectedMethod, setSelectedMethod] = useState({});


  // Fetch data from the server (API 6: Get all data)
  const getAllData = async () => {
    try {
      const response = await axios.get(`${Env.server}/api/additional/getAllData/printingmethod`);
      console.log("Response:", response.data);
      setPrintingMethods(response.data.specifications.data);  // Assuming response structure
      setHeading(response.data.specifications.heading);
      setDescription(response.data.specifications.description);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  // Call fetch function on component mount
  useEffect(() => {
    getAllData();
  }, []);

  // Handle save (API 1: Save or update the heading and description)
  const handleSave = async () => {
    try {
      const response = await axios.post(`${Env.server}/api/additional/saveOrUpdate`, {
        heading,
        description,
        type: "printingmethod",
      });

      console.log("Save/Update Response:", response.data);
      // Refetch data if needed after save
      getAllData();
    } catch (error) {
      console.error("Error saving data", error);
    }
  };


  // image uploader
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // publish image

  const publishImage = () => {
    if (imagesFront.length > 0 || (editOptionOpen === true && editImageStore.length >= 0) ) {
      const formData = new FormData();
      formData.append("imagefront", imagesFront);
      image &&
        image.map((img) => {
          formData.append(`images`, img);
        });

      let objectNames = [];
      axios
        .post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
          let resp = res.data.file;
          for (let i = 0; i < resp.length; i++) {
            objectNames.push("/images/" + resp[i].filename);
          }
          if (res.data.message === "imagesSaved") {
            if(editOptionOpen === true && editImageStore.length === 0){
                // only for update the all image
                updateImagewithoutdbimage(objectNames);
            }else if(editOptionOpen === true && editImageStore.length >= 0){
                // only for update the all image include the db image
                updateDataObjectWithDBimage(objectNames);
            }else{
                publishBlogNow(objectNames);
            }
          } else {
            alert("error not in catch");
          }
        })
        .catch((err) => {
          alert("catch error");
        });
    } else {
      alert("Please select image");
    }
  };



//   update image with db image
  const updateDataObjectWithDBimage = (imagesArray) => {

    const data = {
        title: title,
        image: imagesArray,
        type: "printingmethod",
        id: editedItemId,
      };
      console.log("data", data);
  
      axios
        .patch(`${Env.server}/api/additional/updateDataObjectWithDBimage`, data)
        .then((res) => {
            console.log("success save muzzamal", res.data);
            if (res.data.message === "Success") {
              clearAllHooks()
              getAllData();
            }
        })
        .catch((err) => {
          console.log("Catch Error", err);
        });



  }



//   update image without db image
  const updateImagewithoutdbimage = (imagesArray) => {
    const data = {
        title: title,
        image: imagesArray,
        type: "printingmethod",
        id: editedItemId,
      };
      console.log("data", data);
  
      axios
        .patch(`${Env.server}/api/additional/updateDataupdateDataObjectWithoutDBimage`, data)
        .then((res) => {
            console.log("success save muzzamal", res.data);
            if (res.data.message === "Success") {
              clearAllHooks()
              getAllData();
            }
        })
        .catch((err) => {
          console.log("Catch Error", err);
        });
  }


//   post image
  const publishBlogNow = (imagesArray) => {
    const data = {
      title: title,
      image: imagesArray,
      type: "printingmethod",      
    };
    console.log("data", data);

    axios
      .post(`${Env.server}/api/additional/addData`, data)
      .then((res) => {
          console.log("success save muzzamal", res.data);
          if (res.data.message === "Data added successfully") {
            clearAllHooks()
            getAllData();
          }
      })
      .catch((err) => {
        console.log("Catch Error", err);
      });
  };


//   clear all data hooks
  const clearAllHooks = ()=>{
    setImages([]);
    setImagesFront([]);
    setHeading("");
    setDescription("");
    setTitle("");
    setOpen(false);
  }





  const handleDeleteClick = (method) => {
    setSelectedMethod(method); 
    setDeleteModalOpen(true);  
  };

  const handleCancel = () => {
    setDeleteModalOpen(false); 
    setSelectedMethod({});   
  };

  const handleConfirmDelete = () => {
    console.log("Deleted product ID:", selectedMethod._id);
    axios.delete(`${Env.server}/api/additional/deleteOne/${selectedMethod._id}/printingmethod`)
    .then((res) => {
      console.log("res", res.data);
      getAllData();
      setDeleteModalOpen(false);
      setSelectedMethod({});
    })
    .catch((err) => {
      console.log("err", err);
    });

  };



//   Edit modal system
const handleEditClick = (data)=>{ 
  setTitle(data.title);
  setDescription(data.description);
  setHeading(data.heading);
  setEditImageStore(data.image)
  setEditedItemId(data._id)
  setOpen(true);
  setEditImageShow(true)
  setEditOptionOpen(true)
}

// remove edited before image and add new image
const handleRemoveEditedImage = ()=>{
  setEditImageShow(false)
  setEditImageStore([])
}

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") || currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") || currentPath.includes("signin") ? null : (
            <Header />
          )}

          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <Box sx={{ p: 3 }}>
                    {/* Printing Method Section */}
                    <div className="d-flex justify-content-between align-items-center m-3">
                      <h2 className="text-center flex-grow-1 text-black">
                        Printing Method
                      </h2>
                      <Button
                        className="btn btn-primary"
                        style={{ height: "auto", width: "auto" }}
                        onClick={handleOpen}  // Open modal
                      >
                        Add Printing Method
                      </Button>
                    </div>

                    {/* Heading and Description Fields */}
                    <Box sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        label="Heading"
                        variant="outlined"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                    </Box>

                    {/* Save Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      Save
                    </Button>

                    {/* Break Line */}
                    <hr style={{ margin: "20px 0", border: "none", borderTop: "1px solid black" }} />

                    {/* Card Grid (4 cards per row) */}
                    <Grid container spacing={3}>
        {printingMethods && printingMethods.map((method) => (
          <Grid item xs={12} sm={6} md={3} key={method._id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                width="200"
                image={getAsset(method.image)}
                alt={method.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {method.title}
                </Typography>
                {/* Edit and Delete Buttons */}
                <Button variant="contained" color="primary" onClick={()=>handleEditClick(method)} style={{ marginRight: '8px' }}>
                  Edit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(method)}>
                  Delete
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={handleCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Are you sure you want to delete this item?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            If you delete this item, it cannot be recovered.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

                    {/* Modal for adding new and editing */}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-title"
                      aria-describedby="modal-description"
                    >
                      <Box   sx={{
      ...style,
      maxHeight: '90vh', // Set max height to prevent overflow
      overflowY: 'auto', // Enable vertical scrolling when content overflows
    }}>
                        <Typography id="modal-title" variant="h6" component="h2">
                          {editOptionOpen === true ? "Edit Printing Method" : "Add new Printing Method"}
                        </Typography>

                        {/* Title Field */}
                        <TextField
                          fullWidth
                          label="Title"
                          variant="outlined"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          sx={{ mt: 2, mb: 2 }}
                        />

                        {/* Image Upload */}
                        <div className="form-group text-center">
                                <div
                                  onClick={handleInsideClick}
                                  style={{
                                    marginTop: "60px",
                                    marginBottom: "0px",
                                    width: "200px",
                                    height: "200px",
                                    border: "1px dashed #aaa",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    position: "relative",
                                    overflow: "hidden",
                                    backgroundColor: "black",
                                  }}
                                >
                                  {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                  <h3
                                    style={{
                                      color: "white",
                                      marginTop: "80px",
                                    }}
                                  >
                                    Choose Image
                                  </h3>
                                  <input
                                    {...getInputProps()}
                                    id="fileInput" // Ensure this ID is present
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      opacity: 0,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <br />

                                <Grid container spacing={1}>
                                  {imagesFront && imagesFront.map((file, index) => (
                                    <Grid item key={file.name}>
                                      <div>
                                        <img
                                          src={file.preview}
                                          alt={file.name}
                                          width="200"
                                        />
                                        <IconButton
                                          onClick={() => removeImage(index)}
                                          size="small"
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>

                                {editImageShow === true && editImageStore ? (
    <>
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <img
                src={getAsset(editImageStore)}
                alt="Edit Image"
                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
            />
            <span
                onClick={handleRemoveEditedImage} // Add your remove function here
                style={{
                    position: 'absolute',
                    top: '1px',
                    right: '1px',
                    background: 'red',
                    color: 'white',
                    borderRadius: '50%',
                    width: '25px',
                    height: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                &times;
            </span>
        </div>
    </>
) : null}

                              </div>

                        {/* Modal Buttons */}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                          <Button onClick={handleClose} color="secondary" variant="outlined">
                            Cancel
                          </Button>
                          <Button color="primary" onClick={publishImage} variant="contained">
                            {editOptionOpen === true ? "Update" : "Publish"}
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintingMethod;
