import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Container, Typography, Button, TextField } from '@mui/material';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import Env from '../../Environment/Env';



const Contentpage = () => {
  const [editorContent, setEditorContent] = useState('');
  const [title, setTitle] = useState('');
  const [getData, setGetData] = useState({});
  const currentPath = window.location.pathname;

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    axios
      .get(`${Env.server}/api/contentsection/`)
      .then((res) => {
        console.log("Abc====", res.data)
        setGetData(res.data)
        setEditorContent(res.data.content)
        setTitle(res.data.title)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to handle editor content changes
  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  
  // Function to handle submit button click
  const handleSubmit = async () => {
    console.log(editorContent);
    console.log(title);

    axios.post(`${Env.server}/api/contentsection/`, {
      title:title,
      content: editorContent,
      id: getData._id || "9876"
    })
    .then((res) => {
        console.log("success save muzzamal", res.data);
        getAllData();
    })
    .catch((err) => {
        console.log("Abc=====", err)
    })

  };

  return (
    <>
      <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
        {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}
        <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
          {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
          <div className="content">
            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <Typography variant="h4" align="center">Content Page</Typography>

              <Container>
                <TextField
                  label="Title"
                  fullWidth
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ marginBottom: '20px' }}
                />

                <ReactQuill
                  value={editorContent}
                  onChange={handleEditorChange}
                  placeholder="Write your content here..."
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['link', 'image', 'video'],
                      ['clean'] // Remove formatting button
                    ]
                  }}
                  theme="snow"
                />

                {/* Submit Button */}
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSubmit} 
                  style={{ marginTop: '20px' }}
                >
                  Submit
                </Button>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contentpage;
