import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import ClearIcon from "@mui/icons-material/Clear";
import { getAsset } from "../../utils/helper";
import {
  Container,
  TextField,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextareaAutosize,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import JoditEditor from "jodit-react";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { debounce } from "lodash";

const LabelOther = () => {
  const editor = useRef(null);
  const editor1 = useRef(null);
  const currentPath = window.location.pathname;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [description2, setDescription2] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [focusKeywords, setFocusKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [image, setImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [metaTagInput, setMetaTagInput] = useState(""); // To store the current input
  const [focusKeywordInput, setFocusKeywordInput] = useState(""); // To store the current input for Focus Keywords

  const [responseImages, setResponseImages] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [editid, setEditid] = useState(null);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleDeleteClick = (item) => {
    console.log("Deleted item:", item);
    
    // Perform the delete logic here (e.g., API call to delete item)
  };

  //  edit update
  const updateDataDB = () => {
    const data = {
      id: selectedItem._id,
      title: title,
      description: description,
      description2: description2,
      other: title, // change
      mataTitle: metaTitle,
      mataTags: tags,
      mataDescription: metaDescription,
      FocusKeyWords: focusKeywords,
      productCatagory: category,
    };

    axios
      .patch(`${Env.server}/api/other/updateData`, data)
      .then((res) => {
        console.log("res", res.data);
        getAllCarts();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Handle Edit
  const handleEditClick = (item) => {
    setEditModal(true);
    console.log("Edit item:", item);
    //  all hook in add product
    setEditid(item._id);

    setTitle(item.title);
    setDescription(item.description);
    setDescription2(item.description2);
    setMetaTitle(item.mataTitle);
    setTags(item.mataTags);
    setCategory(item.other);
    setFocusKeywords(item.FocusKeyWords);
    setMetaDescription(item.mataDescription);
    // setImages(item.image);
    // setImagesFront(item.image);
    setMetaTagInput(item.mataTags);
    setCategory(item.productCatagory);

    setSelectedItem(item);

    handleOpenModal();
  };

  //  delete
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Renamed state hook
  const [selectedId, setSelectedId] = useState(null);

  const handleCloseModal = () => {
    clearAllField();
    setShowModal(false);
  };

  const handleDelete = (id) => {
    setSelectedId(id); // Set the id of the item to be deleted
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleConfirmDelete = () => {
    console.log(`Delete item with id: ${selectedId}`); // Log the selected id
    axios.delete(`${Env.server}/api/other/deleteOne/${selectedId}`)
    .then((res) => {
      console.log("res", res.data);
      getAllCarts();
    })
    .catch((err) => {
      console.log("err", err);
    });
    setShowDeleteModal(false); // Close the modal
    // Add the actual delete logic here (e.g., API call)
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false); // Close the modal without deleting
  };

  const [content, setContent] = useState("");
  const [getAllData, setgetAllData] = useState([]);

  // styl;ign
  const cardTextStyle = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3, // Limit to 3 lines
    lineHeight: "1.5em", // Adjust according to your needs
    maxHeight: "4.5em", // Adjust according to line-height * number of lines
  };

  // get All Carts
  useEffect(() => {
    getAllCarts();
  }, []);
  const getAllCarts = () => {
    axios
      .get(`${Env.server}/api/other/getAll`)
      .then((res) => {
        console.log("res", res.data.others);
        setgetAllData(res.data.others);
        clearAllField();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // clear all field
  const clearAllField = () => {
      setEditModal(false);
    setTitle("");
    setDescription("");
    setDescription2("");
    setMetaTitle("");
    setTags([]);
    setCategory("");
    setFocusKeywords([]);
    setMetaDescription("");
    setImages([]);
    setImagesFront([]);
    setMetaTagInput("");
    setFocusKeywordInput("");
  };

  // jodit editor

  const handleChange = debounce((newContent) => {
    setDescription(newContent);
    console.log("newContent", newContent);
  }, 2000); // Debounce of 1 second

  const handleChange2 = debounce((newContent) => {
    setDescription2(newContent);
    console.log("newContent", newContent);
  }, 2000); // Debounce of 1 second

  // tags array function
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && metaTagInput.trim()) {
      if (!tags.includes(metaTagInput.trim())) {
        setTags([...tags, metaTagInput.trim()]);
      }
      setMetaTagInput("");
      e.preventDefault();
    }
  };
  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  // focus keywords array function
  const handleKeyDownFocusKeyword = (e) => {
    if (e.key === "Enter" && focusKeywordInput.trim()) {
      if (!focusKeywords.includes(focusKeywordInput.trim())) {
        setFocusKeywords([...focusKeywords, focusKeywordInput.trim()]);
      }
      setFocusKeywordInput("");
      e.preventDefault();
    }
  };

  // Handle removing Focus Keyword
  const removeFocusKeyword = (indexToRemove) => {
    setFocusKeywords(
      focusKeywords.filter((_, index) => index !== indexToRemove)
    );
  };

  // image uploader
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // publish image

  const publishImage = () => {
    console.log("abc===");
    if (imagesFront.length > 0) {
      console.log("imagesFront", imagesFront);
      console.log("image", image);
      const formData = new FormData();
      formData.append("imagefront", imagesFront);
      image &&
        image.map((img) => {
          formData.append(`images`, img);
        });

      console.log("formData", formData);

      console.log("image", formData);
      let objectNames = [];
      axios
        .post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
          let resp = res.data.file;
          for (let i = 0; i < resp.length; i++) {
            objectNames.push("/images/" + resp[i].filename);
          }
          setResponseImages(objectNames);
          if (res.data.message === "imagesSaved") {
            publishBlogNow(objectNames);
            // console.log("image", objectNames)
          } else {
            alert("error not in catch");
          }
        })
        .catch((err) => {
          alert("catch error");
        });
    } else {
      alert("Please select image");
    }
  };

  const publishBlogNow = (imagesArray) => {
    const data = {
      title: title,
      description: description,
      description2: description2,
      image: imagesArray,
      other: title, // change
      mataTitle: metaTitle,
      mataTags: tags,
      mataDescription: metaDescription,
      FocusKeyWords: focusKeywords,
      productCatagory: category,
    };
    console.log("data", data);

    axios
      .post(`${Env.server}/api/other/addNew`, data)
      .then((res) => {
        let resp = res.data.message;
        if (resp === "successSave") {
          // setUseEffectResp(!useEffectResp);
          // closeAddProductModal();
          setShowModal(false);
          getAllCarts();
          console.log("success save", res.data);
        } else {
          alert("error not in catch");
        }
      })
      .catch((err) => {
        console.log("Catch Error", err);
      });
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="d-flex justify-content-between align-items-center m-3">
                    <h2 className="text-center flex-grow-1 text-black">
                    Sticker Labels
                    </h2>
                    <button
                      className="btn btn-primary"
                      style={{ height: "auto", width: "auto" }}
                      onClick={handleOpenModal}
                    >
                      Add Sticker Labels
                    </button>
                  </div>
                  <br />
                  <br />

                  {/* CART WEBSITE */}

                  <div className="container">
                    <div className="row">
                      {getAllData && getAllData.map((item, index) => (
                        <div
                          key={item._id}
                          className="col-12"
                          style={{ padding: 0 }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{
                              borderBottom: "1px solid #ddd",
                              padding: "10px 0",
                              marginBottom: 0,
                            }}
                          >
                            <div
                              className="col-1 text-center"
                              style={{ fontWeight: "bold" }}
                            >
                              {index + 1}
                            </div>
                            <div className="col-3">
                              <h5
                                className="card-title"
                                style={{
                                  color: "rgb(44, 159, 232)",
                                  margin: 0,
                                }}
                              >
                                {item.title}
                              </h5>
                            </div>
                            <div className="col-3 text-center">
                              <img
                                src={getAsset(item.image[0])}
                                alt={item.title}
                                style={{ height: "100px", width: "100px", objectFit: "cover" }}
                              />
                            </div>
                            <div className="col-3">
                              <p
                                className="card-text"
                                style={{
                                  margin: 0,
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex justify-content-end">
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id={`dropdownMenuButton-${index}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v"></i>
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby={`dropdownMenuButton-${index}`}
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleEditClick(item)}
                                    >
                                      Edit
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      Delete
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Delete Confirmation Modal */}
                    {showDeleteModal && (
                      <div className="modal show d-block" tabIndex="-1">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Confirm Deletion</h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={handleCancelDelete}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <p>Are you sure you want to delete this item?</p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleCancelDelete}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleConfirmDelete}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Modal */}
                  {showModal && (
                    <div
                      className="modal show d-block"
                      tabIndex="-1"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "1000px" }}
                      >
                        <div
                          className="modal-content"
                          style={{ maxHeight: "93vh", overflowY: "auto" }}
                        >
                          <div className="modal-header">
                            <h2
                              className="modal-title text-center w-100"
                              style={{ color: "#2c9fe8", fontWeight: 800 }}
                            >
                              {editModal === true
                                ? "Edit StickerLabel"
                                : "Add New StickerLabel"}
                            </h2>

                            <button
                              type="button"
                              className="close"
                              onClick={handleCloseModal}
                            >
                              <span>&times;</span>
                            </button>
                          </div>
                          <div className="modal-body m-3">
                            <div className="form-group text-left">
                              <label htmlFor="name">
                                <h5>Name:</h5>
                              </label>
                              <input
                                type="text"
                                value={title}
                                id="name"
                                onChange={(e) => setTitle(e.target.value)}
                                className="form-control mb-3"
                                placeholder="Name"
                              />
                            </div>
                            <div className="form-group text-left">
                              <label>
                                <h5>Description One:</h5>
                              </label>
                              {/* Add Jotit Editor component here */}
                              <JoditEditor
                                ref={editor}
                                value={description}
                                config={{
                                  height: 300,
                                }}
                                onChange={(newContent) => {
                                  console.log("acb===", newContent);
                                  handleChange(newContent);
                                }}
                              />
                            </div>
                            <br />
                            <div className="form-group text-left">
                              <label>
                                <h5>Description Two:</h5>
                              </label>
                              {/* Add Jotit Editor component here */}
                              <JoditEditor
                                value={description2}
                                ref={editor1}
                                config={{
                                  height: 300, // Adjust this value to your desired height
                                }}
                                onChange={(newContent) => {
                                  console.log("acb===", newContent);
                                  handleChange2(newContent);
                                }}
                              />
                            </div>

                            {editModal === false ? (
                              <div className="form-group text-center">
                                <div
                                  onClick={handleInsideClick}
                                  style={{
                                    marginTop: "60px",
                                    marginBottom: "0px",
                                    width: "200px",
                                    height: "200px",
                                    border: "1px dashed #aaa",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    position: "relative",
                                    overflow: "hidden",
                                    backgroundColor: "black",
                                  }}
                                >
                                  {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                  <h3
                                    style={{
                                      color: "white",
                                      marginTop: "80px",
                                    }}
                                  >
                                    Choose Image
                                  </h3>
                                  <input
                                    {...getInputProps()}
                                    id="fileInput" // Ensure this ID is present
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      opacity: 0,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <br />

                                <Grid container spacing={1}>
                                  {imagesFront && imagesFront.map((file, index) => (
                                    <Grid item key={file.name}>
                                      <div>
                                        <img
                                          src={file.preview}
                                          alt={file.name}
                                          width="200"
                                        />
                                        <IconButton
                                          onClick={() => removeImage(index)}
                                          size="small"
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            ) : null}

                            <hr
                              className="my-4"
                              style={{ borderColor: "black" }}
                            />
                            <h2
                              className="text-center"
                              style={{ color: "#2c9fe8", fontWeight: 800 }}
                            >
                              SEO INFORMATION
                            </h2>
                            <br />
                            <div className="form-row">
                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  <input
                                    type="text"
                                    id="metaTitle"
                                    className="form-control mb-3"
                                    placeholder="Meta Title"
                                    value={metaTitle}
                                    onChange={(e) =>
                                      setMetaTitle(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  {/* Meta Tags input */}
                                  <input
                                    type="text"
                                    id="metaTags"
                                    className="form-control mb-3"
                                    placeholder="Meta Tags"
                                    value={metaTagInput} // Controlled input value
                                    onChange={(e) =>
                                      setMetaTagInput(e.target.value)
                                    }
                                    onKeyDown={handleKeyDown} // Handle adding tags on Enter key press
                                  />
                                </div>

                                {/* Tags display */}
                                <div style={{ marginTop: "10px" }}>
                                  {tags.map((tag, index) => (
                                    <Chip
                                      key={index}
                                      label={tag}
                                      onDelete={() => removeTag(index)}
                                      style={{
                                        margin: "5px",
                                        borderRadius: "10px",
                                      }}
                                      deleteIcon={<ClearIcon />}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  <input
                                    type="text"
                                    id="category"
                                    className="form-control mb-3"
                                    placeholder="Category"
                                    value={category}
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  <input
                                    type="text"
                                    id="focusKeyword"
                                    className="form-control mb-3"
                                    placeholder="Focus Keyword"
                                    value={focusKeywordInput}
                                    onChange={(e) =>
                                      setFocusKeywordInput(e.target.value)
                                    }
                                    onKeyDown={handleKeyDownFocusKeyword}
                                  />
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                  {focusKeywords.map((keyword, index) => (
                                    <Chip
                                      key={index}
                                      label={keyword}
                                      onDelete={() => removeFocusKeyword(index)}
                                      style={{
                                        margin: "5px",
                                        borderRadius: "10px",
                                      }}
                                      deleteIcon={<ClearIcon />}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-left">
                              <textarea
                                id="description"
                                className="form-control mb-3"
                                placeholder="Description"
                                value={metaDescription}
                                onChange={(e) =>
                                  setMetaDescription(e.target.value)
                                }
                                //  increase rows of textarea
                                rows="5"
                              ></textarea>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleCloseModal}
                            >
                              Close
                            </button>
                            {editModal === true ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={updateDataDB}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={publishImage}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LabelOther;