import React, { useEffect, useState } from "react";
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import {
  TextField,
  Button,
  Box,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Styling for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddFaqs = () => {
  const currentPath = window.location.pathname;

  // State for heading and description fields
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [allIndustry, setAllIndustry] = useState([]);
  const [allShapeStyle, setAllShapeStyle] = useState([]);

  // State for modal visibility and form fields
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [industry, setIndustry] = useState("");
  const [shapeStyle, setShapeStyle] = useState("");
  const [AllDatagetFaq, setAllDatagetFaq] = useState([]);
  const [GetDataId, setGetDataId] = useState("");

  // Delete confirmation modal state
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [faqToDelete, setFaqToDelete] = useState(null);
  const [handleEditOn, setHandleEditOn] = useState(false);
  
  // Open and close modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // Reset form fields when modal is closed
    setQuestion("");
    setAnswer("");
    setIndustry("");
    setShapeStyle("");
    setOpen(false);
    setHandleEditOn(false);
  };

  // Open delete confirmation modal
  const handleDeleteConfirmOpen = (faqId) => {
    setFaqToDelete(faqId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setHandleEditOn(false);
  };

  // Fetch all FAQ data
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    axios
      .get(`${Env.server}/api/faq/GetAll`)
      .then((res) => {
        console.log("abc=====", res.data.faqs);
        setHeading(res.data.faqs[0].heading);
        setDescription(res.data.faqs[0].description);
        setAllDatagetFaq(res.data.faqs[0]);
        setGetDataId(res.data.faqs[0]._id);
        setAllIndustry(res.data.industry);
        setAllShapeStyle(res.data.shapeStyle);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  // Handle save for heading and description (API 1)
  const handleSaveHeadingDescription = async () => {
    try {
      const response = await axios.patch(`${Env.server}/api/faq/Addheading`, {
        heading,
        description
      });
      getAllData();
      console.log("Save/Update Response:", response.data);
    } catch (error) {
      console.error("Error saving data", error);
    }
  };



  const handleUpdateFaq = () => {
    const data = {
      question,
      answer,
      industry,
      shapeStyle,
    };
console.log("data", data);
    axios
      .patch(`${Env.server}/api/faq/updateexistingData/${GetDataId}`, data)
      .then((res) => {
        console.log("abc-----", res.data);
        getAllData(); // Refresh the FAQ list after saving
        handleClose(); // Close the modal after saving
      })
      .catch((err) => {
        console.log("err----", err);
      });
  };
  
  // Handle modal save (API 2)
  const handleSaveFaq = () => {
    const data = {
      question,
      answer,
      industry,
      shapeStyle,
    };

    axios
      .post(`${Env.server}/api/faq/AddQa`, data)
      .then((res) => {
        console.log("abc-----", res.data);
        getAllData(); // Refresh the FAQ list after saving
        handleClose(); // Close the modal after saving
      })
      .catch((err) => {
        console.log("err----", err);
      });
  };

  // Handle edit FAQ
  const handleEditFaq = (faq) => {
    setHandleEditOn(true)
    setQuestion(faq.question);
    setAnswer(faq.answer);
    setIndustry(faq.industry);
    setShapeStyle(faq.shapeStyle);
    setGetDataId(faq._id);
    setOpen(true);
  };

  // Handle delete FAQ
  const handleDeleteFaq = () => {
    console.log("Deleting FAQ with ID:", faqToDelete);
    axios.delete(`${Env.server}/api/faq/deleteOne/${faqToDelete}`)
      .then((res) => {
        console.log("res", res.data);
        setDeleteConfirmOpen(false);
        getAllData();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") || currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") || currentPath.includes("signin") ? null : (
            <Header />
          )}

          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <Box sx={{ p: 3 }}>
                    {/* Heading and Subheading Fields */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="h5" gutterBottom>
                        Add FAQ Heading and Description
                      </Typography>
                      <TextField
                        fullWidth
                        label="Heading"
                        variant="outlined"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveHeadingDescription}
                      >
                        Save Heading and Description
                      </Button>
                    </Box>

                    {/* Divider */}
                    <hr style={{ margin: "20px 0", border: "none", borderTop: "1px solid black" }} />

                    {/* Add FAQ Button */}
                    <div className="d-flex justify-content-between align-items-center m-3">
                      <h2 className="text-center flex-grow-1 text-black">
                        FAQ
                      </h2>
                      <Button
                        className="btn btn-primary"
                        onClick={handleOpen}
                        style={{ height: "auto", width: "auto" }}
                      >
                        Add FAQ
                      </Button>
                    </div>

                    {/* FAQ Cards */}
                    <Grid container spacing={2}>
    {AllDatagetFaq && AllDatagetFaq.QA && AllDatagetFaq.QA.map((faq, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <Card sx={{ mb: 2 }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <Typography variant="h6" sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis'
            }}>
              Question # {index + 1}: <span style={{ color: "#19b3f3" }}>{faq.question}</span>
            </Typography>
            <Typography sx={{ 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis' 
            }}>
              <b>Answer:</b> {faq.answer}
            </Typography>
            {faq.industry && (
              <Typography>
                <b>Industry:</b> {faq.industry}
              </Typography>
            )}
            {faq.shapeStyle && (
              <Typography>
                <b>Shape & Style:</b> {faq.shapeStyle}
              </Typography>
            )}
            {/* Icon buttons wrapper to center align the icons */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}> 
              <IconButton onClick={() => handleEditFaq(faq)} sx={{ mx: 2 }}> {/* mx: 1 adds space between the icons */}
                <EditIcon style={{color:"#19b3f3"}} />
              </IconButton>
              <IconButton onClick={() => handleDeleteConfirmOpen(faq._id)} sx={{ mx: 2 }}> {/* mx: 1 handles space between icons */}
                <DeleteIcon style={{color:"red"}}/>
              </IconButton>
            </div>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
                    {/* Modal for adding/editing FAQ */}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-title"
                      aria-describedby="modal-description"
                    >
                      <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                          {handleEditOn ? "Edit FAQ" : "Add FAQ"}
                        </Typography>

                        {/* Question Field */}
                        <TextField
                          fullWidth
                          label="Question"
                          variant="outlined"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          sx={{ mt: 2 }}
                        />

                        {/* Answer Field */}
                        <TextField
                          fullWidth
                          label="Answer"
                          variant="outlined"
                          value={answer}
                          onChange={(e) => setAnswer(e.target.value)}
                          sx={{ mt: 2 }}
                        />

                        {/* Industry Select */}
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="industry-label">Industry</InputLabel>
                          <Select
                            labelId="industry-label"
                            id="industry"
                            value={industry}
                            label="Industry"
                            onChange={(e) => setIndustry(e.target.value)}
                          >
                            <MenuItem value="">No Choose</MenuItem>
                            {allIndustry && allIndustry.map((industry, index) => (
                              <MenuItem key={index} value={industry.title}>{industry.title}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        
                         <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="shapestyle-label">Shape & Style</InputLabel>
                          <Select
                            labelId="shapestyle-label"
                            id="industry"
                            value={shapeStyle}
                            label="Shape & Style"
                            onChange={(e) => setShapeStyle(e.target.value)}
                          >
                            <MenuItem value="">No Choose</MenuItem>
                            {allShapeStyle && allShapeStyle.map((shapeStyle, index) => (
                              <MenuItem key={index} value={shapeStyle.title}>{shapeStyle.title}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* Save Button */}
                       {handleEditOn ? (
                         <>
                         <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateFaq}
                          sx={{ mt: 2 }}
                        >
                          Update
                        </Button>
                         </>
                       ):(
                        <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSaveFaq}
                          sx={{ mt: 2 }}
                        >
                          Save
                        </Button>
                        </>
                       )}
                      </Box>
                    </Modal>

                    {/* Delete confirmation dialog */}
                    <Dialog
                      open={deleteConfirmOpen}
                      onClose={handleDeleteConfirmClose}
                      aria-labelledby="delete-confirm-title"
                      aria-describedby="delete-confirm-description"
                    >
                      <DialogTitle id="delete-confirm-title">Delete FAQ</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="delete-confirm-description">
                          Are you sure you want to delete this FAQ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDeleteConfirmClose} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleDeleteFaq} color="secondary" autoFocus>
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFaqs;
