import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import ClearIcon from "@mui/icons-material/Clear";
import { getAsset } from "../../utils/helper";
import {
  Container,
  TextField,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextareaAutosize,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import JoditEditor from "jodit-react";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { debounce } from "lodash";

const Materials = () => {
  const editor = useRef(null);
  const editor1 = useRef(null);
  const currentPath = window.location.pathname;

  const [title, setTitle] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [image, setImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [ImagesGet, setImagesGet] = useState([]);


  const [responseImages, setResponseImages] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [editid, setEditid] = useState(null);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleDeleteClick = (item) => {
    console.log("Deleted item:", item);
    
    // Perform the delete logic here (e.g., API call to delete item)
  };

const [selectedImage, setSelectedImage] = useState(null);
const [EditImageSelector, setEditImageSelector] = useState(""); 
const [EditImageSelected, setEditImageSelected] = useState(false); 
const [HandleSingleImage, setHandleSingleImage] = useState(false); 

  const handleImageSelect = (img) => {
    console.log("abc====", img);
    setSelectedImage(img); // Set selected image
    handleEditImage(img); // Call the edit function
    console.log("abc====",selectedItem)
    setHandleSingleImage(true)
  };


  const handleDeleteEditImage =(e)=>{
    console.log("e",e)
    console.log("e",selectedItem)

    const updatedImages = selectedItem.image.filter((img) => img !== e);
    console.log("Updated Image Array:", updatedImages);
    setImagesGet(updatedImages)


 const data = {
      id: selectedItem._id,
      title: title,
      material: title, // change
      image: updatedImages
    };

    axios
      .patch(`${Env.server}/api/material/updateData`, data)
      .then((res) => {
        console.log("res", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  
  const handleEditImage=(e)=>{
    console.log("edit", e)
    setEditImageSelector(e)
    setEditImageSelected(true)
    }






  //  edit update
  const updateDataDB = () => {
    const data = {
      id: selectedItem._id,
      title: title,
      material: title, // change
    };

    axios
      .patch(`${Env.server}/api/material/updateData`, data)
      .then((res) => {
        console.log("res", res.data);
        getAllCarts();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Handle Edit
  const handleEditClick = (item) => {
    console.log("Edit item:", item);
    //  all hook in add product
    setEditModal(true)
    setEditid(item._id);
    setTitle(item.title);
    setImagesGet(item.image);

    setSelectedItem(item);

    handleOpenModal();
  };

  //  delete
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Renamed state hook
  const [selectedId, setSelectedId] = useState(null);

  const handleCloseModal = () => {
    clearAllField();
    setShowModal(false);
  };

  const handleDelete = (id) => {
    setSelectedId(id); // Set the id of the item to be deleted
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleConfirmDelete = () => {
    console.log(`Delete item with id: ${selectedId}`); // Log the selected id
    axios.delete(`${Env.server}/api/material/deleteOne/${selectedId}`)
    .then((res) => {
      console.log("res", res.data);
      getAllCarts();
    })
    .catch((err) => {
      console.log("err", err);
    });
    setShowDeleteModal(false); // Close the modal
    // Add the actual delete logic here (e.g., API call)
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false); // Close the modal without deleting
  };

  const [getAllData, setgetAllData] = useState([]);

  // styl;ign
  const cardTextStyle = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3, // Limit to 3 lines
    lineHeight: "1.5em", // Adjust according to your needs
    maxHeight: "4.5em", // Adjust according to line-height * number of lines
  };

  // get All Carts
  useEffect(() => {
    getAllCarts();
  }, []);
  const getAllCarts = () => {
    axios
      .get(`${Env.server}/api/material/getAll`)
      .then((res) => {
        console.log("res", res.data.material);
        setgetAllData(res.data.material);
        clearAllField();
    setShowModal(false);

      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // clear all field
  const clearAllField = () => {
      setEditModal(false);
    setTitle("");
    setImages([]);
    setImagesFront([]);
  };


  // image uploader
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // publish image

  const publishImage = () => {
    console.log("abc===");
      console.log("imagesFront", imagesFront);
      console.log("image", image);
      const formData = new FormData();
      formData.append("imagefront", imagesFront);
      image &&
        image.map((img) => {
          formData.append(`images`, img);
        });

      console.log("formData", formData);

      console.log("image", formData);
      let objectNames = [];
      axios
        .post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
          let resp = res.data.file;
          for (let i = 0; i < resp.length; i++) {
            objectNames.push("/images/" + resp[i].filename);
          }
          setResponseImages(objectNames);
          if (res.data.message === "imagesSaved") {
            if(HandleSingleImage === true){
              handlesingleimageeditfun(objectNames)
            }else if(HandleSingleImage === false && editModal === true ){
              handleEditAddImage(objectNames)
            }else{
              console.log("img=====", objectNames)
              publishBlogNow(objectNames);
            }

          } else {
            alert("error not in catch");
          }
        })
        .catch((err) => {
          alert("catch error");
        });
    
  };


  const handleEditAddImage=(image)=>{
    console.log("abc====",image)
    selectedItem.image.push(...image);
    console.log("new====", selectedItem.image)
    setShowModal(false);

     const data = {
      id: selectedItem._id,
      title: title,
      material: title, // change
      image: selectedItem.image
    };

    axios
      .patch(`${Env.server}/api/material/updateData`, data)
      .then((res) => {
        console.log("res", res.data);
        getAllCarts();
      })
      .catch((err) => {
        console.log("err", err);
      });
  }


  const handlesingleimageeditfun =(image)=>{
    selectedItem.image = selectedItem.image.reduce((acc, img) => {
      return img === selectedImage ? acc.concat(image) : acc.concat(img);
    }, []);
    console.log("image update", selectedItem)
    setShowModal(false);
    setHandleSingleImage(false)
    
    const data = {
      id: selectedItem._id,
      title: title,
      material: title, // change
      image: selectedItem.image
    };

    axios
      .patch(`${Env.server}/api/material/updateData`, data) 
      .then((res) => {
        console.log("res", res.data);
        getAllCarts();
      })
      .catch((err) => {
        console.log("err", err);
      });

  }

  const publishBlogNow = (imagesArray) => {
    const data = {
      title: title,
      image: imagesArray,
    };
    console.log("data", data);

    axios
      .post(`${Env.server}/api/material/addNew`, data)
      .then((res) => {
        let resp = res.data.message;
        if (resp === "successSave") {
          // setUseEffectResp(!useEffectResp);
          // closeAddProductModal();
          setShowModal(false);
          getAllCarts();
          console.log("success save", res.data);
        } else {
          alert("error not in catch");
        }
      })
      .catch((err) => {
        console.log("Catch Error", err);
      });
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="d-flex justify-content-between align-items-center m-3">
                    <h2 className="text-center flex-grow-1 text-black">
                      Materials
                    </h2>
                    <button
                      className="btn btn-primary"
                      style={{ height: "auto", width: "auto" }}
                      onClick={handleOpenModal}
                    >
                      Add Materials
                    </button>
                  </div>
                  <br />
                  <br />

                  {/* CART WEBSITE */}

                  <div className="container">
                  <div className="row">
  {getAllData &&
    getAllData.map((item) => (
      <div key={item._id} className="col-12 col-md-6 col-lg-3" style={{ padding: '10px' }}>
        <div className="card" style={{ border: '1px solid #ddd' }}>
          {/* Image on top */}
          <div className="text-center">
            <img
              src={getAsset(item.image[0])}
              alt={item.title}
              style={{
                height: '250px',
                width: '250px',
                objectFit: 'cover',
              }}
            />
          </div>

          <div>
          <br/>
            {/* Title */}
            <div className="text-center">
              <h5
                className="card-title"
                style={{
                  color: 'rgb(44, 159, 232)',
                  margin: 0,
                }}
              >
                {item.title}
              </h5>
            </div>

            {/* Buttons: Edit and Delete */}
            <div className="d-flex justify-content-center mt-2">
              <button
                className="btn btn-primary me-2"
                onClick={() => handleEditClick(item)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(item._id)}
              >
                Delete
              </button>
            </div>

            {/* Description */}
            <div className="text-center mt-3">
              <p
                className="card-text"
                style={{
                  margin: 0,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    ))}
</div>


                    {/* Delete Confirmation Modal */}
                    {showDeleteModal && (
                      <div className="modal show d-block" tabIndex="-1">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Confirm Deletion</h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={handleCancelDelete}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <p>Are you sure you want to delete this item?</p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleCancelDelete}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleConfirmDelete}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Modal */}
                  {showModal && (
                    <div
                      className="modal show d-block"
                      tabIndex="-1"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "1000px" }}
                      >
                        <div
                          className="modal-content"
                          style={{ maxHeight: "93vh", overflowY: "auto" }}
                        >
                          <div className="modal-header">
                            <h2
                              className="modal-title text-center w-100"
                              style={{ color: "#2c9fe8", fontWeight: 800 }}
                            >
                              {editModal === true
                                ? "Edit Material"
                                : "Add New Material"}
                            </h2>

                            <button
                              type="button"
                              className="close"
                              onClick={handleCloseModal}
                            >
                              <span>&times;</span>
                            </button>
                          </div>
                          <div className="modal-body m-3">
                            <div className="form-group text-left">
                              <label htmlFor="name">
                                <h5>Name:</h5>
                              </label>
                              <input
                                type="text"
                                value={title}
                                id="name"
                                onChange={(e) => setTitle(e.target.value)}
                                className="form-control mb-3"
                                placeholder="Name"
                              />
                            </div>
                          
                            {ImagesGet && ImagesGet.map((image, index) => (
  <div
    key={index}
    className="text-center"
    style={{
      flex: '0 0 25%',
      padding: '10px',
      boxSizing: 'border-box',
    }}
  >
    <img
      src={getAsset(image)} // Use the current image from the map
      style={{ height: '100px', 
      width: '100px',
      border: selectedImage === image ? '2px solid blue' : 'none', // Apply blue border to selected image
       }}

      alt={`Image ${index + 1}`} // Optional: Add alt text
    />
    <div style={{ marginTop: '5px' }}>
      <span
        style={{
          display: 'inline-block',
          color: 'blue',
          cursor: 'pointer',
          marginRight: '15px',
        }} // Call handleEdit with the current image
        onClick={() => handleImageSelect(image)}

      >
        Edit
      </span>
      <span
        style={{
          display: 'inline-block',
          color: 'blue',
          cursor: 'pointer',
        }}
        onClick={()=> handleDeleteEditImage(image)}
      >
        Delete
      </span>
    </div>
  </div>
))}


                              <div className="form-group text-center">
                                <div
                                  onClick={handleInsideClick}
                                  style={{
                                    marginTop: "60px",
                                    marginBottom: "0px",
                                    width: "200px",
                                    height: "200px",
                                    border: "1px dashed #aaa",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    position: "relative",
                                    overflow: "hidden",
                                    backgroundColor: "black",
                                  }}
                                >
                                  {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                  <h3
                                    style={{
                                      color: "white",
                                      marginTop: "80px",
                                    }}
                                  >
                                    Choose Image
                                  </h3>
                                  <input
                                    {...getInputProps()}
                                    id="fileInput" // Ensure this ID is present
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      opacity: 0,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <br />

                                <Grid container spacing={1}>
                                  {imagesFront.map((file, index) => (
                                    <Grid item key={file.name}>
                                      <div>
                                        <img
                                          src={file.preview}
                                          alt={file.name}
                                          width="200"
                                        />
                                        <IconButton
                                          onClick={() => removeImage(index)}
                                          size="small"
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>

                            <hr
                              className="my-4"
                              style={{ borderColor: "black" }}
                            />
                            
                            
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleCloseModal}
                            >
                              Close
                            </button>
                            {editModal === true ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={publishImage}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={publishImage}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Materials;