import React, {useRef, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import { useDropzone } from "react-dropzone";
import JoditEditor from "jodit-react";
import { debounce } from "lodash";
import axios from "axios";
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import {
    Container,
    TextField,
    Box,
    Typography,
    IconButton,
    Grid,
    TextareaAutosize,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";


const AddProducts = () => {
  const editor = useRef(null);
  const editor1 = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [OpenEditImageModal, setOpenEditImageModal] = useState(false);
    
    const currentPath = window.location.pathname;

    const handleShowModal = () => setShowModal(true);
    
    const handleCloseModal = () => {
      setShowModal(false)
      setOpenEditModal(false)
    };
    const [focusKeywords, setFocusKeywords] = useState([]);

    const [title, setTitle] = useState("");
    const [ industry, setIndustry ] = useState("");
    const [ shapeStyle, setShapeStyle ] = useState("");
    const [ price, setPrice ] = useState("");
    const [ productCategory, setProductCategory ] = useState("");

    const [description, setDescription] = useState("");
    const [largeDescription, setLargeDescription] = useState("");
    const [metaDescription, setmetaDescription] = useState(""); 
    const [image, setImages] = useState([]);
    const [imagesFront, setImagesFront] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [focusKeywordInput, setFocusKeywordInput] = useState(""); // To store the current input for Focus Keywords

  const [metaTagInput, setMetaTagInput] = useState(""); // To store the current input
const [getAllProducts, setGetAllProducts] = useState([]);
const [EditImageData, setEditImageData] = useState({});
const [getSearchProducts, setGetSearchProducts] = useState([]);


const [getSelectIndustry, setGetSelectIndustry] = useState([]);
const [getSelectMaterial, setGetSelectMaterial] = useState([]);
const [getSelectShapeStyle, setGetSelectShapeStyle] = useState([]);
const [openDeleteModal, setOpenDeleteModal] = useState(false);
const [openEditModal, setOpenEditModal] = useState(false);
const [OpenDeleteId, setOpenDeleteId] = useState("");
const [OpenEditId, setOpenEditId] = useState(""); 
const [EditImageSelector, setEditImageSelector] = useState(""); 
const [EditImageSelected, setEditImageSelected] = useState(false); 



// delete product
const handleDeleteClick=(e)=>{
  setOpenDeleteModal(true)
  setOpenDeleteId(e._id)
} 

const confirmDelete=()=>{
  console.log("abc=====", OpenDeleteId)
  axios.delete(`${Env.server}/api/product/DeleteSinglProduct/${OpenDeleteId}`)
  .then((res)=>{
    setOpenDeleteModal(false)
    setOpenDeleteId("")
    GetAllSelectFieldData();
  })
  .catch((err) => {
    console.log("abc====", err)
  })
}

const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (item) => {
    setSelectedImage(item); // Set selected image
    handleEditImage(item); // Call the edit function
  };

// edit & delete image
const handleEditImage=(e)=>{
console.log("edit", e)
setEditImageSelector(e)
setEditImageSelected(true)
console.log("edit", EditImageData)
}



const handleDeleteEditImage=(e)=>{
  console.log("delete", EditImageData._id)
  console.log("delete", e)
  console.log("delete", EditImageData.image)

  const updatedImages = EditImageData.image.filter((img) => img !== e);
  console.log("Updated Image Array:", updatedImages);
  setEditImageData({
    ...EditImageData,
    image: updatedImages,
  });
  console.log("image", EditImageData.image)

  axios.patch(`${Env.server}/api/product/updateProduct/${EditImageData._id}`, {image:updatedImages})
  .then((res) => {
    setOpenEditImageModal(false)
    GetAllSelectFieldData();
  })
  .catch((err) => {
    console.log("abc====", err)
  })
}


// pagination
const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 25;
  const totalPages = Math.ceil(getAllProducts.length / productsPerPage);

  const currentProducts = getAllProducts.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };



  // handle search
  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase(); // Convert the search input to lowercase
    // Check if getSearchProducts contains the full list of products
    if (search) {
      console.log("Searching for:", search); // Log the search term
      const filteredProducts = getSearchProducts.filter(product =>
        product.title.toLowerCase().includes(search) // Filter based on the title
      );
      console.log("Filtered Products:", filteredProducts); // Log the filtered results
      setGetAllProducts(filteredProducts); // Update displayed products
    } else {
      setGetAllProducts(getSearchProducts); // Ensure all products are displayed when search is cleared
    }
  };
  


  // nextprev style
  const nextprev = {
    backgroundColor:"blue",
    color:"white",
    borderRadius:"5px",
    padding:"5px",
  }






// edit
const handleEditClick =(e)=>{
  setOpenEditModal(true)
  setOpenEditId(e._id)
  setShowModal(true)
  setTitle(e.title)
  setIndustry(e.industry)
  setShapeStyle(e.shapeStyle)
  setPrice(e.price)
  setProductCategory(e.productCategory)
  setDescription(e.description)
  setLargeDescription(e.largeDescription)
  setmetaDescription(e.mataDescription) 
  setMetaTitle(e.mataTitle)
  setTags(e.mataTags)
  setFocusKeywords(e.FocusKeyWords)
  setProductCategory(e.productCatagory)
  setPrice(e.price)
  setMetaTagInput(e.metaTagInput)
  setLargeDescription(e.description2)
}

const updateProduct=()=>{
  console.log("abc=====", OpenEditId)
  console.log("abc=====", title)
  console.log("abc=====", industry)
  console.log("abc=====", shapeStyle)
  console.log("abc=====", price)
  console.log("abc=====", productCategory)
  console.log("abc=====", description)
  console.log("abc=====", largeDescription)
  console.log("abc=====", metaDescription)
  console.log("abc=====", metaTitle)
  console.log("abc=====", tags)
  console.log("abc=====", focusKeywords)
  console.log("abc=====", metaTagInput)
  console.log("abc=====", largeDescription)
  console.log("abc=====", metaTagInput)
  console.log("abc=====", metaTagInput)
  const data = {
    title: title,
    description: description, 
    description2:largeDescription,
    industry: industry, 
    shapeStyle: shapeStyle, 
    price: price,
    productCatagory:productCategory,
    mataTitle: metaTitle,
    mataTags: tags,
    mataDescription: metaDescription,
    FocusKeyWords: focusKeywords
  };
  console.log("data", data);

  axios.patch(`${Env.server}/api/product/updateProduct/${OpenEditId}`, data)
  .then((res) => {
    console.log("res====", res.data)
    GetAllSelectFieldData();
    if(res.data.message === "Product updated successfully"){
      setOpenEditModal(false)
      setShowModal(false)
    }
  })
  .catch((err) => {
    console.log("err====", err)
  })
}

const cardTextStyle = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 3, // Limit to 3 lines
  lineHeight: "1.5em", // Adjust according to your needs
  maxHeight: "4.5em", // Adjust according to line-height * number of lines
};

useEffect(() => {
    GetAllSelectFieldData();
  }, []);

// Get industries , shapestyle, material
const GetAllSelectFieldData = () => {
  axios.get(`${Env.server}/api/product/getAllforPostProduct`)
  .then((res) => {
    console.log("res", res.data)
    setGetSelectIndustry(res.data.industries)
    setGetSelectMaterial(res.data.material)
    setGetSelectShapeStyle(res.data.shape)
    setGetAllProducts(res.data.products)
    setGetSearchProducts(res.data.products)
  })
  .catch((err) => {
    console.log("err", err)
  })
}

    // tags array function
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && metaTagInput.trim()) {
          if (!tags.includes(metaTagInput.trim())) {
            setTags([...tags, metaTagInput.trim()]);
          }
          setMetaTagInput("");
          e.preventDefault();
        }
      };
      const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
      };
    
      // focus keywords array function
      const handleKeyDownFocusKeyword = (e) => {
        if (e.key === "Enter" && focusKeywordInput.trim()) {
          if (!focusKeywords.includes(focusKeywordInput.trim())) {
            setFocusKeywords([...focusKeywords, focusKeywordInput.trim()]);
          }
          setFocusKeywordInput("");
          e.preventDefault();
        }
      };
    
      // Handle removing Focus Keyword
      const removeFocusKeyword = (indexToRemove) => {
        setFocusKeywords(
          focusKeywords.filter((_, index) => index !== indexToRemove)
        );
      };


// jodit editor

    const handleChange = debounce((newContent) => {
        setDescription(newContent);
        console.log("newContent", newContent);
      }, 2000); // Debounce of 1 second

      const handleChangeLargeDesc = debounce((newContent) => {
        setLargeDescription(newContent);
        console.log("newContent", newContent);
      }, 2000); // Debounce of 1 second


     // image uploader and publish
     const publishImage = () => {
        console.log("abc===");
        if (imagesFront.length > 0) {
          console.log("imagesFront", imagesFront);
          console.log("image", image);
          const formData = new FormData();
          formData.append("imagefront", imagesFront);

          image && image.map((img) => {
              formData.append(`images`, img);
            });
    
          console.log("formData", formData);
    
          console.log("image", formData);
          let objectNames = [];
          axios
            .post(`${Env.server}/api/upload/multiple`, formData)
            .then((res) => {
              let resp = res.data.file;
              for (let i = 0; i < resp.length; i++) {
                objectNames.push("/images/" + resp[i].filename);
              }
            //   setResponseImages(objectNames);
              if (res.data.message === "imagesSaved") {
                console.log("image", OpenEditImageModal)
                console.log("image", EditImageSelected)

                  if(EditImageSelected === true){
                    UpdateImageEdit(objectNames)
                  }else if(OpenEditImageModal === true && EditImageSelected === false){
                    UpdateImageNewUpload(objectNames)
                  }else{
                    console.log("image", objectNames)
                    publishBlogNow(objectNames);
                  }
              } else {
                alert("error not in catch");
              }
            })
            .catch((err) => {
              alert("catch error");
            });
        } else {
          alert("Please select image");
        }
      };
    

      const UpdateImageNewUpload = (image)=>{
        console.log("new====", image);
        console.log("true====", EditImageSelected);
        console.log("old====", EditImageSelector);
        console.log("all====", EditImageData.image);
        
        // Use spread operator to push each string in `image` array individually
        EditImageData.image.push(...image);
        
        console.log("Updated EditImageData:", EditImageData);
        
        axios.patch(`${Env.server}/api/product/updateProduct/${EditImageData._id}`, {image:EditImageData.image})
        .then((res) => {
          setOpenEditImageModal(false)
          GetAllSelectFieldData();
        })
        .catch((err) => {
          console.log("abc====", err)
        })

      }

      const UpdateImageEdit = (image) =>{
console.log("new====", image)
console.log("true====", EditImageSelected)
console.log("old====", EditImageSelector)
console.log("all====", EditImageData.image)

EditImageData.image = EditImageData.image.reduce((acc, img) => {
  return img === EditImageSelector ? acc.concat(image) : acc.concat(img);
}, []);

console.log("Updated EditImageData:", EditImageData);



axios.patch(`${Env.server}/api/product/updateProduct/${EditImageData._id}`, {image:EditImageData.image})
.then((res) => {
  setOpenEditImageModal(false)
  GetAllSelectFieldData();
})
.catch((err) => {
  console.log("abc====", err)
})



// axios.patch(`${Env.server}/api/product/updateProduct/${EditImageSelector}`, {image:image})

      }


      const publishBlogNow = (imagesArray) => {

        const data = {
            title: title,
            description: description, 
            description2:largeDescription,
            industry: industry, 
            shapeStyle: shapeStyle, 
            price: price,
            image: imagesArray,
            productCatagory:productCategory,
            mataTitle: metaTitle,
            mataTags: tags,
            mataDescription: metaDescription,
            FocusKeyWords: focusKeywords
          };
          console.log("data", data);
     

       
      
          axios.post(`${Env.server}/api/product/postNew`, data)
          .then((res) => {
              let resp = res.data.message;
              if (resp === "successSave") {
                  // setUseEffectResp(!useEffectResp);
                  // closeAddProductModal();
                  setShowModal(false);
                  GetAllSelectFieldData();

              } else {
                  alert("error not in catch");
              }
          })
          .catch((err) => {
              console.log("Catch Error", err);
          })
      };
    
    




  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles && acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles && acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };


    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                <div
  className="d-flex justify-content-between m-3 align-items-center"
  style={{ display: 'flex', alignItems: 'center' }}
>
  <div style={{ flexBasis: '33%' }}>
    <input
      type="text"
      placeholder="Search products..."
      className="form-control"
      onChange={handleSearch}
      style={{ width: '100%' }}
    />
  </div>

  <div style={{ flexBasis: '33%', textAlign: 'center' }}>
    <h2 style={{ margin: 0 }}>Products</h2>
  </div>

  <div style={{ flexBasis: '33%', textAlign: 'right' }}>
    <Button variant="primary" onClick={handleShowModal}>
      Add Product
    </Button>
  </div>
</div>

                                    <div className="container">
                                    <div className="row">
<div>
      {/* Render the current products */}
     {currentProducts.length > 0 ? (
      <>
      {currentProducts.map((item, index) => (
        <div key={item._id} className="col-12" style={{ padding: 0 }}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid #ddd",
              padding: "10px 0",
              marginBottom: 0,
            }}
          >
            <div className="col-1 text-center" style={{ fontWeight: "bold" }}>
              {index + 1 + (currentPage - 1) * productsPerPage}
            </div>
            
            <div className="col-3">
              <h5
                className="card-title"
                style={{
                  color: "rgb(44, 159, 232)",
                  margin: 0,
                }}
              >
                {item.title}
              </h5>
            </div>
            
            <div className="col-3 text-center">
  <img
    src={getAsset(item.image[0])}
    alt={item.title}
    style={{ height: "100px", width: "100px", objectFit: "cover" }}
  />
  <span style={{ display: 'block', marginTop: '5px', color: 'blue', cursor: 'pointer' }} onClick={()=> {
    setOpenEditImageModal(true)
    setEditImageData(item)
    }} >
    Edit
  </span>
</div>
            
            <div className="col-3">
              <p
                className="card-text"
                style={{
                  margin: 0,
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </div>
            
            <div className="col-2 d-flex justify-content-end">
              <div className="d-flex justify-content-center">
                <FaEdit
                  className="text-primary mx-2"
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => handleEditClick(item)}
                />
                <FaTrashAlt
                  onClick={() => handleDeleteClick(item)}
                  className="text-danger mx-2"
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      </>
    ):(
      <>
      <h3>Loading...</h3>
      <h3>Will solve this soon</h3>
      </>
    )}
<br/>
<br/>
      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1} style={nextprev}>
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>
          Page {currentPage} of {totalPages} 
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages} style={nextprev}>
          Next
        </button>
      </div>
<br/>

    </div>
</div>

                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


              {/* Delete Confirmation Modal */}
              {openDeleteModal && (
                      <div className="modal show d-block" tabIndex="-1">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Confirm Deletion</h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={() => setOpenDeleteModal(false)}
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <p>Are you sure you want to delete this item?</p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setOpenDeleteModal(false)}
                              >
                                Cancel
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={confirmDelete}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}


{/* Edit Image Modal */}

{OpenEditImageModal && (
                    <div
                      className="modal show d-block"
                      tabIndex="-1"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "1000px" }}
                      >
                        <div
                          className="modal-content"
                          style={{ maxHeight: "93vh", overflowY: "auto" }}
                        >
                          <div className="modal-header">
                            <h2
                              className="modal-title text-center w-100"
                              style={{ color: "#2c9fe8", fontWeight: 800 }}
                            >
                              Edit Image
                            </h2>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setOpenEditImageModal(false)}
                            >
                              <span>&times;</span>
                            </button>
                          </div>
                          <div className="modal-body m-3">
                          <div className="d-flex flex-wrap">
      {EditImageData && EditImageData.image && EditImageData.image.map((item) => (
        <div
          key={item._id}
          className="text-center"
          style={{
            flex: '0 0 25%',
            padding: '10px',
            boxSizing: 'border-box',
            border: selectedImage === item ? '2px solid blue' : 'none', // Apply blue border to selected image
          }}
        >
          <img
            src={getAsset(item)}
            alt={EditImageData.title}
            style={{ height: '100px', width: '100px' }}
          />
          <div style={{ marginTop: '5px' }}>
            <span
              style={{ display: 'inline-block', color: 'blue', cursor: 'pointer', marginRight: '15px' }}
              onClick={() => handleImageSelect(item)}
            >
              Edit
            </span>
            <span
              style={{ display: 'inline-block', color: 'blue', cursor: 'pointer' }}
              onClick={() => handleDeleteEditImage(item)}
            >
              Delete
            </span>
          </div>
        </div>
      ))}
    </div>


<div
                                    onClick={handleInsideClick}
                                    style={{
                                        marginTop: "60px",
                                        marginBottom: "0px",
                                        width: '200px',
                                        height: '200px',
                                        border: '1px dashed #aaa',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        backgroundColor: "black"
                                    }}
                                >
                                    {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                    <h3 style={{ color: "white", marginTop: "80px" }}>Choose Image</h3>
                                    <input
                                        {...getInputProps()}
                                        id="fileInput" // Ensure this ID is present
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                    />

                                </div>
                                <div>
                                <Grid container spacing={1}>
                                    {imagesFront.map((file, index) => (
                                        <Grid item key={file.name}>
                                            <div>
                                                <img src={file.preview} alt={file.name} width="200" />
                                                <IconButton onClick={() => removeImage(index)} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                                </div>
                        
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={()=>setOpenEditModal(false)}
                            >
                              Close
                            </button>
                           
                           
                            <div>
  {openEditModal ? (
    // Show the Update button if openEditModal is true
    <button type="button" className="btn btn-primary" onClick={updateProduct}>
      Update
    </button>
  ) : (
    // Show the Submit button if openEditModal is false
    <button type="button" className="btn btn-primary" onClick={publishImage}>
      Submit
    </button>
  )}
</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  )}



            {/* Modal */}
            {showModal && (
                    <div
                      className="modal show d-block"
                      tabIndex="-1"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "1000px" }}
                      >
                        <div
                          className="modal-content"
                          style={{ maxHeight: "93vh", overflowY: "auto" }}
                        >
                          <div className="modal-header">
                            <h2
                              className="modal-title text-center w-100"
                              style={{ color: "#2c9fe8", fontWeight: 800 }}
                            >
                              Add New Product
                            </h2>
                            <button
                              type="button"
                              className="close"
                              onClick={handleCloseModal}
                            >
                              <span>&times;</span>
                            </button>
                          </div>
                          <div className="modal-body m-3">
                            <div className="form-group text-left">
                              <label htmlFor="name">
                                <h5>Product Name:</h5>
                              </label>
                              <input
                                type="text"
                                id="name"
                                value={title}
                                onChange={(e)=> setTitle(e.target.value)}
                                className="form-control mb-3"
                                placeholder="Product Name"
                              />
                            </div> 

                            <div className="form-group text-left">
      <label htmlFor="industry">
        <h5>Industry:</h5>
      </label>
      <select
        id="industry"
        className="form-control mb-3"
        value={industry}  // The selected value (_id)
        onChange={(e) => setIndustry(e.target.value)}  // Store the _id of the selected industry
      >
        <option value="" disabled>
          Select Industry
        </option>
        {getSelectIndustry && getSelectIndustry.map((ind) => (
          <option key={ind._id} value={ind._id}>
            {ind.title}
          </option>
        ))}
      </select>
    </div>

    <div className="form-group text-left">
      <label htmlFor="shape">
        <h5>Shape & Style:</h5>
      </label>
      <select
        id="shape"
        className="form-control mb-3"
        value={shapeStyle}  // The selected value (_id)
        onChange={(e) => setShapeStyle(e.target.value)}  // Store the _id of the selected industry
      >
        <option value="" disabled>
          Select Shape Style
        </option>
        {getSelectShapeStyle && getSelectShapeStyle.map((ind) => (
          <option key={ind._id} value={ind._id}>
            {ind.title}
          </option>
        ))}
      </select>
    </div>

    

                            <div className="form-group text-left">
                              <label htmlFor="price">
                                <h5>Price:</h5>
                              </label>
                              <input
                                type="number"
                                value={price}
                                onChange={(e)=> setPrice(e.target.value)}
                                id="price"
                                className="form-control mb-3"
                                placeholder="Price"
                              />
                            </div>

                            <div className="form-group text-left">
                              <label>
                                <h5>Description:</h5>
                              </label>
                              {/* Add Jotit Editor component here */}
                              <JoditEditor
                                ref={editor}
                                value={description}
                                config={{
                                  height: 300,
                                }}
                                onChange={(newContent) => {
                                  console.log("acb===", newContent);
                                  handleChange(newContent);
                                }}
                              />
                            </div>
                            <br />
                            <div className="form-group text-left">
                              <label>
                                <h5>Large Description:</h5>
                              </label>
                              {/* Add Jotit Editor component here */}
                              <JoditEditor
                                ref={editor1}
                                value={largeDescription}
                                config={{
                                  height: 300,
                                }}
                                onChange={(newContent) => {
                                  console.log("acb===", newContent);
                                  handleChangeLargeDesc(newContent);
                                }}
                              />
                            </div>
                            <br/>
                            
                            {openEditModal === false ? (
                            <div className="form-group text-center">
                              <div
                                onClick={handleInsideClick}
                                style={{
                                  marginTop: "60px",
                                  marginBottom: "0px",
                                  width: "200px",
                                  height: "200px",
                                  border: "1px dashed #aaa",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  position: "relative",
                                  overflow: "hidden",
                                  backgroundColor: "black",
                                }}
                              >
                                {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                <h3
                                  style={{ color: "white", marginTop: "80px" }}
                                >
                                  Choose Image
                                </h3>
                                <input
                                  {...getInputProps()}
                                  id="fileInput" // Ensure this ID is present
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <br />

                              <Grid container spacing={1}>
                                {imagesFront && imagesFront.map((file, index) => (
                                  <Grid item key={file.name}>
                                    <div>
                                      <img
                                        src={file.preview}
                                        alt={file.name}
                                        width="200"
                                      />
                                      <IconButton
                                        onClick={() => removeImage(index)}
                                        size="small"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </div>
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                            ):null}
                            
                            

                            <hr
                              className="my-4"
                              style={{ borderColor: "black" }}
                            />

                            <h2
                              className="text-center"
                              style={{ color: "#2c9fe8", fontWeight: 800 }}
                            >
                              SEO INFORMATION
                            </h2>
                            <br />
                            <div className="form-row">
                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  <input
                                    type="text"
                                    id="metaTitle"
                                    className="form-control mb-3"
                                    placeholder="Meta Title"
                                    value={metaTitle}
                                    onChange={(e) =>
                                      setMetaTitle(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  {/* Meta Tags input */}
                                  <input
                                    type="text"
                                    id="metaTags"
                                    className="form-control mb-3"
                                    placeholder="Meta Tags"
                                    value={metaTagInput} // Controlled input value
                                    onChange={(e) =>
                                      setMetaTagInput(e.target.value)
                                    }
                                    onKeyDown={handleKeyDown} // Handle adding tags on Enter key press
                                  />
                                </div>

                                {/* Tags display */}
                                <div style={{ marginTop: "10px" }}>
                                  {tags && tags.map((tag, index) => (
                                    <Chip
                                      key={index}
                                      label={tag}
                                      onDelete={() => removeTag(index)}
                                      style={{
                                        margin: "5px",
                                        borderRadius: "10px",
                                      }}
                                      deleteIcon={<ClearIcon />}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  <input
                                    type="text"
                                    id="category"
                                    className="form-control mb-3"
                                    value={productCategory}
                                    placeholder="Category"
                                    onChange={(e) =>
                                      setProductCategory(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group text-left">
                                  <input
                                    type="text"
                                    id="focusKeyword"
                                    className="form-control mb-3"
                                    placeholder="Focus Keyword"
                                    value={focusKeywordInput}
                                    onChange={(e) =>
                                      setFocusKeywordInput(e.target.value)
                                    }
                                    onKeyDown={handleKeyDownFocusKeyword}
                                  />
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                  {focusKeywords && focusKeywords.map((keyword, index) => (
                                    <Chip
                                      key={index}
                                      label={keyword}
                                      onDelete={() => removeFocusKeyword(index)}
                                      style={{
                                        margin: "5px",
                                        borderRadius: "10px",
                                      }}
                                      deleteIcon={<ClearIcon />}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-left">
                              <textarea
                                id="description"
                                className="form-control mb-3"
                                placeholder="Description"
                                //  increase rows of textarea
                                value={metaDescription}
                                onChange={(e) => setmetaDescription(e.target.value)}
                                style={{ height: "150px" }}
                                rows="5"
                              ></textarea>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleCloseModal}
                            >
                              Close
                            </button>
                           
                           
                            <div>
  {openEditModal ? (
    // Show the Update button if openEditModal is true
    <button type="button" className="btn btn-primary" onClick={updateProduct}>
      Update
    </button>
  ) : (
    // Show the Submit button if openEditModal is false
    <button type="button" className="btn btn-primary" onClick={publishImage}>
      Submit
    </button>
  )}
</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
        </>
    );
}

export default AddProducts;