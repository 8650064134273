import React, { Component, useEffect, useState } from 'react';
import Linechart from './linechart';
import Nextcharts from './nextcharts';
import Morecharts from './morecharts';
import Sidebar from '../main/sidebar';
import Searchbar from '../main/navigation/searchbar';
import Header from '../main/header';
import axios from 'axios';
import Env from '../../Environment/Env';
import { useDropzone } from 'react-dropzone';
import { getAsset } from '../../utils/helper';

const AddSubCatagory = () => {

    const currentPath = window.location.pathname;


    const [getAllProductRes, setgetAllProductRes] = useState([]);
    const [getAllCatagories, setgetAllCatagories] = useState([]);
    
    const [getAllSubatagory, setgetAllSubatagory] = useState([]);
    const [selectCatagoryName, setSelectCatagoryName] = useState([]);
    const [selectCatagoryID, setSelectCatagoryID] = useState([]);
    const [subCatagoryName, setSubCatagoryName] = useState("");


    const [usefectTrue, setUsefectTrue] = useState(false);
    const [image, setImages] = useState("");
    const [responseImages, setResponseImages] = useState([]);
    const [imagesFront, setImagesFront] = useState([]);
    const [deleteID, setDeleteID] = useState("");


  

    useEffect(() => {
        getAllProduct();
        getAllSubCatagory();
    }, [])
    useEffect(() => {
        getAllProduct();
        // getAllSubCatagory();
    }, [usefectTrue])

    const handleDeleteFun = () => {
        console.log("c====>>>", deleteID)
        axios.delete(`${Env.server}/api/subcatagory/DeleteSubcatagory/${deleteID}`)
            .then(   (res) => {
                console.log("abc===>>>", res.data.message);
                getAllSubCatagory();
            })
            .catch((err) => {
                console.log("err====>>>", err);
            })
    }

    


    
    // Edit 
    
    const [editableIndex2, setEditableIndex2] = useState(-1); // Index of the currently editable item
    const [editedContent2, setEditedContent2] = useState(''); // Content being edited
  
  
  
    const handleEdit2 = (index, initialContent) => {
      setEditableIndex2(index);
      setEditedContent2(initialContent);
    };
  
    const handleSave2 = (index, id) => {
      // Perform saving operation with edited content
      console.log(`Saved changes for item at   ${id} :  ${index}: ${editedContent2}`);
      setEditableIndex2(-1); // Reset editable state
      if (editedContent2 === "") {
        UpdateEditData({ subcatagory: "xyz" }, id)
        console.log("editedContent2===>", editedContent2)
    } else {
        console.log("editedContent2===>", editedContent2)
        UpdateEditData({ subcatagory: editedContent2 }, id)
     }
  
    };
  
    
  
    const handleInputChange2 = (event) => {
      setEditedContent2(event.target.value);
    };
    
    
    
    // Edit 


    // updation

    const UpdateEditData = (data, id) => {
        console.log("abc====", data);
        console.log("abc====", id);
        axios.patch(`${Env.server}/api/subcatagory/updateEditedData1/${id}`, data)
          .then((res) => {
            console.log("res.data====", res.data);
            getAllProduct(); 
            getAllSubCatagory();
          })
          .catch((err) => {
            console.log("err====", err)
          })
      }



// updation

   

    const fetchSCatagoryByCatagoryID = (e) => {
        const selectedValue = e.target.value;
        console.log("Selected value:", selectedValue); 
       if(selectedValue === "all"){
           getAllSubCatagory();
        }else{
           axios.get(`${Env.server}/api/subcatagory/GetSubcatagoryByCatagoryID/${selectedValue}`)
               .then((res) => {
                   console.log("res===>>>", res.data.subcatagories);
                   setgetAllSubatagory(res.data.subcatagories);
               })
               .catch((err) => {
                   console.log("err===>>>", err);
               })
        }
    }

    const handleSelectChange5 = (event) => {
        const selectedID = event.target.value;
        const selectedIndex = event.target.selectedIndex;
        const selectedName = event.target.options[selectedIndex].text;
        setSelectCatagoryName(selectedName);
        setSelectCatagoryID(selectedID);
        // setSelectCatagoryID
        console.log(`Selected option: ${selectedName}`);
        console.log(`Selected option: ${selectedID}`);
    };


    const submit = (img) => {
        const data = {
            catagoryid: selectCatagoryID,
            catagory: selectCatagoryName,
            imageSubCatagory: img,
            subcatagory: subCatagoryName
        }
        if (!data) {
            alert("Fill All Fields")
        }
        
        console.log("FinalData====>>>", data)
        axios.post(`${Env.server}/api/subcatagory/addnew`, data)
            .then((res) => {
                console.log("abc===>>", res.data.products);
                getAllSubCatagory();
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }




    const publishImage = () => {
        const formData = new FormData();
        formData.append('imagefront', imagesFront);
        console.log("muzz1-====", imagesFront);
        console.log("muzz2-====", image)
        image.forEach((img, index) => {
            formData.append(`images`, img);
        });
        let objectNames = [];
        axios.post(`${Env.server}/api/upload/multiple`, formData)
            .then((res) => {
                let resp = res.data.file;
                for (let i = 0; i < resp.length; i++) {
                    objectNames.push("/images/" + resp[i].filename);
                }
                setResponseImages(objectNames);
                if (res.data.message === "imagesSaved") {
                    submit(objectNames);
                } else {
                    alert("error not in catch")
                }
            })
            .catch((err) => {
                console.log("err------ooo", err)
                alert("catch error");
            })
    }

    const getAllSubCatagory = () => {
        axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
            .then((res) => {
                console.log("abc===>>", res.data.message);
                console.log("abc===>>", res.data.subcatagories);
                setgetAllSubatagory(res.data.subcatagories);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }


    const getAllProduct = () => {
        axios.get(`${Env.server}/api/catagory/GetAllCatagory`)
            .then((res) => {
                console.log("abc===>>", res.data.message);
                console.log("allcatagories===>>", res.data.catagories);
                setgetAllProductRes(res.data.catagories);
                setgetAllCatagories(res.data.catagories);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }

    const handleInsideClick = (e) => {
        e.stopPropagation();
        const isClickable = e.target.tagName === 'DIV' || e.target.tagName === 'INPUT'; // Check if the clicked element is the drop zone or input
        if (isClickable) {
            if (e.target.tagName === 'DIV') {
                document.getElementById('fileInput').click(); // Trigger the file input click event
            }
        }
    };
    const onDrop = (acceptedFiles) => {
        setImages(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setImagesFront(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    };
    const removeImage = (index) => {
        const updatedImagesfront = [...imagesFront];
        updatedImagesfront.splice(index, 1);
        setImagesFront(updatedImagesfront)

        const updatedImages = [...image];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
    });



    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="card-header">
                                            <h2 className="text-center f-bold mb-3"><b>Add Sub-Category Form</b></h2>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <select className="form-control mr-2" style={{ height: "40px" }} onChange={(e) => fetchSCatagoryByCatagoryID(e)}>
                                                <option value="all">All Brands</option>
                                                {getAllCatagories && getAllCatagories.map((data, index) => (
                                                    <option key={index} value={data._id}>{data.catagory}</option>
                                                ))}
                                            </select>

                                            <button className="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong" style={{ height: "40px" }}>Add Sub-Category</button>
                                        </div>
                                    </div>


                                    <div className="card-body" style={{
                                        "height": "auto"
                                    }}>

                                        <div class="container">
                                            <div class="row">
                                                {getAllSubatagory.map((data, index) => (
                                                    <div class="col-md-3" >
                                                        <div class="card" key={index} style={{ position: 'relative', marginBottom: "10px" }}>
                                                            <span
                                                                className="delete-icon"
                                                                data-toggle="modal"
                                                                data-target="#exampleModalLong1"
                                                                onClick={() => setDeleteID(data._id)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    position: 'absolute',
                                                                    color: 'red',
                                                                    fontSize: '20px',
                                                                    top: '5px',
                                                                    right: '10px',
                                                                    zIndex: 2,
                                                                }}
                                                            >
                                                                &#x2716; {/* Unicode for an "X" character */}
                                                            </span>
                                                            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: "7px" }}>
                                                                <img
                                                                    src={getAsset(data.imageSubCatagory[0])}
                                                                    class="card-img-top"
                                                                    alt="..."
                                                                    style={{ height: '50px', width: 'auto', marginRight: '10px' }}
                                                                />
                                                               
                                                            <div className="card-body">
                                      {editableIndex2 === index ? (
                                        <input
                                          type="text"
                                          value={editedContent2}
                                          onChange={handleInputChange2}
                                          onBlur={() => handleSave2(index, data._id)}
                                          autoFocus
                                        />
                                      ) : (
                                        <h5 style={{ fontSize: "15px" }} className="card-title" onClick={() => handleEdit2(index, data.subcatagory)}>
                                          {data.subcatagory}
                                        </h5>
                                      )}
                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>


                                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">Add Sub-Catagory</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className="form-group">
                                                            <label htmlFor="condition" style={{ display: 'block', textAlign: 'left' }}>Catagory Name:</label>
                                                            <select id="selectField" className='form-control' value={selectCatagoryID} onChange={handleSelectChange5}>
                                                                <option value="">Choose Catagory</option>
                                                                {getAllProductRes.map((data, index) => (
                                                                    <>
                                                                        <option key={index} style={{ color: "black" }} value={data._id}>{data.catagory}</option>
                                                                    </>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="item" style={{ display: 'block', textAlign: 'left' }}>Sub-Catagory Name:</label>
                                                            <input type='text' id="item" className='form-control' onChange={(e) => setSubCatagoryName(e.target.value)} placeholder='Type Sub Catagory Name' />
                                                        </div>

                                                        <div
                                                            onClick={handleInsideClick}
                                                            style={{
                                                                marginLeft: "20px",
                                                                width: '100px',
                                                                height: '100px',
                                                                border: '1px dashed #aaa',
                                                                borderRadius: '20px',
                                                                cursor: 'pointer',
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                backgroundColor: "black"
                                                            }}
                                                        >
                                                            {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                                            <h5 style={{ color: "white", marginTop: "30px" }}>Choose Image</h5>
                                                            <input
                                                                {...getInputProps()}
                                                                id="fileInput" // Ensure this ID is present
                                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                                            />

                                                        </div>
                                                        <br />
                                                        <center>  <div className='container' spacing={1}>
                                                            {imagesFront.map((file, index) => (
                                                                <div item key={file.name}>
                                                                    <div>
                                                                        <img src={file.preview} alt={file.name} width="300" />
                                                                        <span onClick={() => removeImage(index)} size="small">
                                                                            <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        </center> 




                                                   
                                                    </div>



                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={publishImage}>Publish</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">Delete Sub-Catagory</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <h4>Are you sure to delete?</h4>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-danger" 
                                                        data-dismiss="modal"
                                                         onClick={handleDeleteFun}  >Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default AddSubCatagory;