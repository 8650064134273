import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Chip, Box, Typography } from '@mui/material';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import Env from '../../Environment/Env';


const MetainfoHomepage = () => {
    const currentPath = window.location.pathname;
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [newKeyword, setNewKeyword] = useState('');
    const [tags, setTags] = useState([]); // State for tags
    const [newTag, setNewTag] = useState(''); // State for new tag
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    const [ogImage, setOgImage] = useState('');
    const [ogUrl, setOgUrl] = useState('');

    useEffect(() => {
       getAllData()
    }, []);

    const getAllData = () => {
        axios
        .get(`${Env.server}/api/homeseo/getall`)
        .then((res) => {
            console.log("Abc====", res.data.homeseo)
            let resp = res.data.homeseo
            setTitle(resp.title)
            setDescription(resp.description)
            setKeywords(resp.keywords)
            setTags(resp.tags)
            setOgTitle(resp.ogTitle)
            setOgDescription(resp.ogDescription)
            setOgImage(resp.ogImage)
            setOgUrl(resp.ogUrl)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleKeywordAdd = (event) => {
        if (event.key === 'Enter' && newKeyword.trim()) {
            event.preventDefault();
            setKeywords((prevKeywords) => [...prevKeywords, newKeyword.trim()]);
            setNewKeyword('');
        }
    };

    const handleTagAdd = (event) => {
        if (event.key === 'Enter' && newTag.trim()) {
            event.preventDefault();
            setTags((prevTags) => [...prevTags, newTag.trim()]);
            setNewTag('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({
            title,
            description,
            keywords,
            tags, // Include tags in the submission
            ogTitle,
            ogDescription,
            ogImage,
            ogUrl,
        });

        axios
            .post(`${Env.server}/api/homeseo/postNew`, {
                title,
                description,
                keywords,
                tags, // Include tags in the submission
                ogTitle,
                ogDescription,
                ogImage,
                ogUrl,
            })
            .then((res) => {
                console.log("abc====", res.data)
                resetForm();
            })
            .catch((err) => {
                console.log("err====", err)
            });
    };

    const resetForm = () => {
        setTitle('');
        setDescription('');
        setKeywords([]);
        setNewKeyword('');
        setTags([]); // Reset tags
        setNewTag(''); // Reset new tag
        setOgTitle('');
        setOgDescription('');
        setOgImage('');
        setOgUrl('');
    };

    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}
                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div style={{ backgroundColor: "white", padding: "25px" }}>
                            <Typography variant="h4" align="center">Meta Information Form</Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Page Title"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <TextField
                                    label="Meta Description"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                
                                {/* Keywords Input */}
                                <TextField
                                    label="Add Keywords"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={newKeyword}
                                    onChange={(e) => setNewKeyword(e.target.value)}
                                    onKeyDown={handleKeywordAdd}
                                />
                                {/* Display Keywords */}
                                <Box mt={2}>
                                    {keywords && keywords.length > 0 && (
                                        <Box 
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '5px',
                                                margin: '5px 0',
                                            }}
                                        >
                                            {keywords.map((keyword, index) => (
                                                <Chip
                                                    key={index}
                                                    label={keyword}
                                                    onDelete={() => setKeywords(keywords.filter((k) => k !== keyword))}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                </Box>

                                {/* Tags Input */}
                                <TextField
                                    label="Add Tags"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                    onKeyDown={handleTagAdd}
                                />
                                {/* Display Tags */}
                                <Box mt={2}>
                                    {tags.length > 0 && (
                                        <Box 
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '5px',
                                                margin: '5px 0',
                                            }}
                                        >
                                            {tags.map((tag, index) => (
                                                <Chip
                                                    key={index}
                                                    label={tag}
                                                    onDelete={() => setTags(tags.filter((t) => t !== tag))}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                </Box>

                                <TextField
                                    label="Open Graph Title"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={ogTitle}
                                    onChange={(e) => setOgTitle(e.target.value)}
                                />
                                <TextField
                                    label="Open Graph Description"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={ogDescription}
                                    onChange={(e) => setOgDescription(e.target.value)}
                                />
                                <TextField
                                    label="Open Graph Image URL"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={ogImage}
                                    onChange={(e) => setOgImage(e.target.value)}
                                />
                                <TextField
                                    label="Open Graph URL"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={ogUrl}
                                    onChange={(e) => setOgUrl(e.target.value)}
                                />
                                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MetainfoHomepage;
