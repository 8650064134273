import React, { Component, useEffect, useState } from 'react';
import Linechart from './linechart';
import Nextcharts from './nextcharts';
import Morecharts from './morecharts';
import Sidebar from '../main/sidebar';
import Searchbar from '../main/navigation/searchbar';
import Header from '../main/header';
import axios from 'axios';
import Env from '../../Environment/Env';
import { useDropzone } from 'react-dropzone';
import { getAsset } from '../../utils/helper';

const Sellerproducts = () => {

    const currentPath = window.location.pathname;

    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const [selectedOption3, setSelectedOption3] = useState('');
    const [selectedOption4, setSelectedOption4] = useState('');
    const [selectedOption5, setSelectedOption5] = useState('');
    const [getAllProductRes, setgetAllProductRes] = useState([]);
    const [usefectTrue, setUsefectTrue] = useState(false);
    const [image, setImages] = useState("");
    const [responseImages, setResponseImages] = useState([]);
    const [imagesFront, setImagesFront] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteID, setDeleteID] = useState("");




    useEffect(() => {
        getAllProduct();
    }, [])
    useEffect(() => {
        getAllProduct();
    }, [usefectTrue])




    const handleDeleteFun = () => {
        console.log("c====>>>", deleteID)
        axios.delete(`${Env.server}/api/product/DeleteSinglProduct/${deleteID}`)
            .then((res) => {
                console.log("abc===>>>", res.data.message);
                getAllProduct();
            })
            .catch((err) => {
                console.log("err====>>>", err);
            })
    }




    const handleSelectChange1 = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        console.log(`Selected option: ${selectedValue}`);
    };
    const handleSelectChange2 = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        console.log(`Selected option: ${selectedValue}`);
    };
    const handleSelectChange3 = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption3(selectedValue);
        console.log(`Selected option: ${selectedValue}`);
    };
    const handleSelectChange4 = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption4(selectedValue);
        console.log(`Selected option: ${selectedValue}`);
    };
    const handleSelectChange5 = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption5(selectedValue);
        console.log(`Selected option: ${selectedValue}`);
    };


    const submit = (imageFile) => {
        console.log("abc====", selectedOption1);
        console.log("abc====", selectedOption2);
        console.log("abc====", selectedOption3);
        console.log("abc====", selectedOption4);
        console.log("abc====", selectedOption5);

        const data = {
            item: selectedOption1,
            model: selectedOption2,
            edition: selectedOption3,
            storage: selectedOption4,
            condition: selectedOption5,
            addedby: "seller",
            image: imageFile
        }
        if (!data) {
            alert("Fill All Fields")
        }

        axios.post(`${Env.server}/api/product/postNew`, data)
            .then((res) => {
                console.log("abc===>>", res.data.message)
                console.log("abc===>>", res.data.products)
                getAllProduct();
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }



    const publishImage = () => {
        const formData = new FormData();
        formData.append('imagefront', imagesFront);
        console.log("muzz1-====", imagesFront)
        console.log("muzz2-====", image)
        image.forEach((img, index) => {
            formData.append(`images`, img);
        });
        let objectNames = [];
        axios.post(`${Env.server}/api/upload/multiple`, formData)
            .then((res) => {
                let resp = res.data.file;
                for (let i = 0; i < resp.length; i++) {
                    objectNames.push("/images/" + resp[i].filename);
                }
                setResponseImages(objectNames);
                if (res.data.message === "imagesSaved") {
                    submit(objectNames);
                } else {
                    alert("error not in catch")
                }
            })
            .catch((err) => {
                console.log("err------ooo", err)
                alert("catch error");
            })
    }

    const getAllProduct = () => {
        console.log(";;;;;;;;;;;;;;;;;; ")
        setImages([]);
        setImagesFront([])
        setSelectedOption1("")
        setSelectedOption2("")
        setSelectedOption3("")
        setSelectedOption4("")
        setSelectedOption5("")

        axios.get(`${Env.server}/api/order/getall`)
            .then((res) => {
                console.log("abc===>>", res.data.message)
                console.log("abc===>>", res.data.orders)
                setgetAllProductRes(res.data.orders);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }

        
    const handleInsideClick = (e) => {
        e.stopPropagation();
        const isClickable = e.target.tagName === 'DIV' || e.target.tagName === 'INPUT'; // Check if the clicked element is the drop zone or input
        if (isClickable) {
            if (e.target.tagName === 'DIV') {
                document.getElementById('fileInput').click(); // Trigger the file input click event
            }
        }
    };
    const onDrop = (acceptedFiles) => {
        setImages(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setImagesFront(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    };
    const removeImage = (index) => {
        const updatedImagesfront = [...imagesFront];
        updatedImagesfront.splice(index, 1);
        setImagesFront(updatedImagesfront)

        const updatedImages = [...image];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
    });



    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className='d-flex'>
                                        <div className="card-header justify-content-center">
                                            <h2 className="text-center">Seller Products</h2>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{
                                        "height": "auto"
                                    }}>
 

                                        <div class="container">
                                            <div className="row">
                                                {getAllProductRes.map((data, index) => (
                                                    <div className="col-md-3" key={index}>
                                                        <div className="card">
                                                            <span
                                                                className="delete-icon"
                                                                data-toggle="modal" data-target="#exampleModalLong1"
                                                                onClick={() => setDeleteID(data._id)}
                                                                style={{ cursor: 'pointer', position: 'absolute', color: "red", fontSize: "20px", top: '10px', right: '10px', zIndex: 2 }}
                                                            >
                                                                &#x2716; {/* Unicode for an "X" character */}
                                                            </span>
                                                            {data.product.map((product, productIndex) => (
                                                                <React.Fragment key={productIndex}>
                                                                    <img src={getAsset(product.data && product.data.image[0])} className="card-img-top" alt="..." style={{ height: "250px", width: "auto" }} />
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Name: {data.name}</h5>
                                                                        <h5 className="card-title">Price: £{product.data.price}</h5>
                                                                        {/* Render other product details as needed */}
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>


                                        <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">Add Product</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <h4>Are you sure to delete?</h4>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={handleDeleteFun}  >Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <!-- Modal --> */}
                                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">Add Product</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>
                                                            <label htmlFor="selectField ">Items:</label>
                                                            <select id="selectField" className='form-control' value={selectedOption1} onChange={handleSelectChange1}>
                                                                <option value="">Select...</option>
                                                                <option value="X-Box One">X-Box One</option>
                                                                <option value="Samsung">Samsung</option>
                                                                <option value="watch">watch</option>
                                                                <option value="HandFree">HandFree</option>
                                                                <option value="Ball">Ball</option>
                                                                <option value="Buds">Buds</option>
                                                                <option value="Speaker">Speaker</option>
                                                                <option value="Calculators">Calculators</option>
                                                                <option value="Drons">Drons</option>
                                                                <option value="Headphones">Headphones</option>

                                                                {/* Add more options as needed */}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label >Model Name:</label>
                                                            <select id="selectField" className='form-control' value={selectedOption2} onChange={handleSelectChange2}>
                                                                <option value="">Select...</option>
                                                                <option value="X-Box One">X-Box One</option>
                                                                <option value="Xbox One Elite">Xbox One Elite</option>
                                                                <option value="option3 X-BoxOne S">X-BoxOne S</option>
                                                                {/* Add more options as needed */}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label >Edition:</label>
                                                            <select id="selectField" className='form-control' value={selectedOption3} onChange={handleSelectChange3}>
                                                                <option value="">Select...</option>
                                                                <option value="Advanced Warfare">Advanced Warfare</option>
                                                                <option value="Forza Motorspot">Forza Motorspot</option>
                                                                <option value="Halo 5">Halo 5</option>
                                                                {/* Add more options as needed */}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label >Storage:</label>
                                                            <select id="selectField" className='form-control' value={selectedOption4} onChange={handleSelectChange4}>
                                                                <option value="">Select...</option>
                                                                <option value="0.5TB">0.5TB</option>
                                                                <option value="1TB">1TB</option>
                                                                <option value="2TB">2TB</option>
                                                                {/* Add more options as needed */}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label >Condition:</label>
                                                            <select id="selectField" className='form-control' value={selectedOption5} onChange={handleSelectChange5}>
                                                                <option value="">Select...</option>
                                                                <option value="Boxed">Boxed</option>
                                                                <option value="Discounted">Discounted</option>
                                                                <option value="Unboxed">Unboxed</option>
                                                                {/* Add more options as needed */}
                                                            </select>
                                                        </div>

                                                        <div
                                                            onClick={handleInsideClick}
                                                            style={{
                                                                marginTop: "20px",
                                                                marginLeft: "20px",
                                                                marginBottom: "0px",
                                                                width: '100px',
                                                                height: '100px',
                                                                border: '1px dashed #aaa',
                                                                borderRadius: '50%',
                                                                cursor: 'pointer',
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                backgroundColor: "black"
                                                            }}
                                                        >
                                                            {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                                            <h5 style={{ color: "white", marginTop: "30px" }}>Choose Image</h5>
                                                            <input
                                                                {...getInputProps()}
                                                                id="fileInput" // Ensure this ID is present
                                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                                            />

                                                        </div>
                                                        <center>  <div className='container' spacing={1}>
                                                            {imagesFront.map((file, index) => (
                                                                <div item key={file.name}>
                                                                    <div>
                                                                        <img src={file.preview} alt={file.name} width="300" />
                                                                        <span onClick={() => removeImage(index)} size="small">
                                                                            <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        </center>


                                                    </div>


                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={publishImage}>Publish</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default Sellerproducts;