import React, { useState, useRef, useEffect } from 'react';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import { Container, TextField, Button, Modal, Box, Typography, IconButton, Grid, TextareaAutosize, Chip, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';




const HeaderImages = () => {
    const editor = useRef(null);
    const currentPath = window.location.pathname;

    const [showModal, setShowModal] = useState(false);
    const [image, setImages] = useState("");
    const [imagesFront, setImagesFront] = useState([]);
    const [responseImages, setResponseImages] = useState([]);
    const [getImage , setGetImage] = useState([])



    useEffect(() => {
        getAllheader()
    },[])

    const getAllheader = () => {
        axios.get(`${Env.server}/api/header/headerImgGet`)
        .then((res) => {
            console.log("res", res)
            setGetImage(res.data.header.img)
        })
        .catch((err) => {
            console.log("err", err)
        })
    }
   

// image publish
const publishImage = () => {
    console.log("imagesFront", imagesFront)
    console.log("image", image)
    const formData = new FormData();
    formData.append('imagefront', imagesFront);
    image.forEach((img, index) => {
        formData.append(`images`, img);
    });
    console.log("image", formData)
    let objectNames = [];
    axios.post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
            let resp = res.data.file;
            for (let i = 0; i < resp.length; i++) {
                objectNames.push("/images/" + resp[i].filename);
            }
            setResponseImages(objectNames);
            if (res.data.message === "imagesSaved") {
                // publishBlogNow(objectNames);
                console.log("abc--===", objectNames)
                uploadDatabase(objectNames)
                // setShowModal(false)


            } else {
                alert("error not in catch")
            }
        })
        .catch((err) => {
            alert("catch error");
        })
}

const uploadDatabase = (objectNames) => {
    console.log("objectNames", objectNames)
    axios.post(`${Env.server}/api/header/headerImgPost`,  objectNames)
    .then((res) => {
        console.log("res", res)
        setShowModal(false)
        getAllheader();
    })
    .catch((err) => {
        console.log("err", err)
    })
}


// image upload

    const onDrop = (acceptedFiles) => { 
        setImages(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setImagesFront(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    };
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
    });
    const handleInsideClick = (e) => {
        e.stopPropagation();
        const isClickable = e.target.tagName === 'DIV' || e.target.tagName === 'INPUT'; // Check if the clicked element is the drop zone or input
        if (isClickable) {
            if (e.target.tagName === 'DIV') {
                document.getElementById('fileInput').click(); // Trigger the file input click event
            }
        }
    };
    
    const removeImage = (index) => {
        const updatedImagesfront = [...imagesFront];
        updatedImagesfront.splice(index, 1);
        setImagesFront(updatedImagesfront)

        const updatedImages = [...image];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };


    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className="d-flex justify-content-between align-items-center m-3">
                                        <h2 className="text-center flex-grow-1 text-black">Header Images</h2>
                                        <button
                                            className="btn btn-primary"
                                            style={{ height: 'auto', width: 'auto' }}
                                            onClick={handleOpenModal}
                                        >
                                            Edit Banner
                                        </button>
                                    </div>
                                    <br/>
                                    <br/>

                                    {/* Images Grid */}
                                    <div className="container">
                                        <div className="row">
                                            {getImage.map((image, index) => (
                                                <div className="col-md-6 mb-4" key={index}>
                                                    <img
                                                        src={getAsset(image)}
                                                        alt={`Image ${index + 1}`}
                                                        className="img-fluid rounded"
                                                        style={{maxHeight: "500px"}}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Modal */}
                                    {showModal && (
                                        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                                            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "700px" }} >
                                                <div className="modal-content" 
                          style={{ maxHeight: "93vh", overflowY: "auto" }}
                                                >
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Edit Header Images</h5>
                                                        <button type="button" className="close" onClick={handleCloseModal}>
                                                            <span>&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                    <div
                                    onClick={handleInsideClick}
                                    style={{
                                        marginTop: "60px",
                                        marginBottom: "0px",
                                        width: '200px',
                                        height: '200px',
                                        border: '1px dashed #aaa',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        backgroundColor: "black"
                                    }}
                                >
                                    {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                    <h3 style={{ color: "white", marginTop: "80px" }}>Choose Image</h3>
                                    <input
                                        {...getInputProps()}
                                        id="fileInput" // Ensure this ID is present
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                    />

                                </div>

<br/>
                                <Grid container spacing={1}>
                                    {imagesFront.map((file, index) => (
                                        <Grid item key={file.name}>
                                            <div>
                                                <img src={file.preview} alt={file.name} width="200" />
                                                <IconButton onClick={() => removeImage(index)} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={handleCloseModal}
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="button" className="btn btn-primary" onClick={publishImage}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderImages;
