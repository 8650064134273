import React, { useRef, useState, useEffect } from 'react';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import axios from 'axios';
import Env from '../../Environment/Env';
import { useDropzone } from "react-dropzone";
import ClearIcon from "@mui/icons-material/Clear";

import JoditEditor from "jodit-react";
import {
    Container,
    TextField,
    Button,
    Modal,
    Box,
    Typography,
    IconButton,
    Grid,
    TextareaAutosize,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { debounce } from "lodash";






const Settings = () => {
    const editor1 = useRef(null);
    const editor2 = useRef(null);
    const editor3 = useRef(null);
    const editor4 = useRef(null);
    const currentPath = window.location.pathname;


    const [image, setImages] = useState([]);
    const [imagesFront, setImagesFront] = useState([]);
  const [responseImages, setResponseImages] = useState([]);
  const [getAllDatares, setGetAllDatares] = useState([]);
  
  const [industrialHeading, setIndustrialHeading] = useState("");
  const [howToOrderHeading, setHowToOrderHeading] = useState("");
  const [howToOrder, setHowToOrder] = useState("");


useEffect(() => {
GetAllData();
  }, []);

  const handleChange = debounce((newContent) => {
    setHowToOrder(newContent);
    console.log("newContent", newContent);
  }, 2000); // Debounce of 1 second

  const GetAllData = () => {
    axios.get(`${Env.server}/api/homepage/getAll`)
      .then((res) => {
console.log("abc====",res.data.hoempage)
setIndustrialHeading(res.data.hoempage.IndusHead)
setHowToOrderHeading(res.data.hoempage.orderHead)
setHowToOrder(res.data.hoempage.howToOrder)

setGetAllDatares(res.data.hoempage)
      })
      .catch((err) => {
        console.log("err====>>>", err)
      })
  }


      // image uploader
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // publish image

  const publishImage = () => {
    console.log("abc===");
    if (imagesFront.length > 0) {
      console.log("imagesFront", imagesFront);
      console.log("image", image);
      const formData = new FormData();
      formData.append("imagefront", imagesFront);
      image &&
        image.map((img) => {
          formData.append(`images`, img);
        });

      console.log("formData", formData);

      console.log("image", formData);
      let objectNames = [];
      axios
        .post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
          let resp = res.data.file;
          for (let i = 0; i < resp.length; i++) {
            objectNames.push("/images/" + resp[i].filename);
          }
          setResponseImages(objectNames);
          if (res.data.message === "imagesSaved") {
            publishBlogNow(objectNames);
            // console.log("image", objectNames)
          } else {
            alert("error not in catch");
          }
        })
        .catch((err) => {
          alert("catch error");
        });
    } else {
        const data = {
            IndusHead: industrialHeading,
            howToOrder: howToOrder,
            orderHead: howToOrderHeading
        };
        console.log("data", data);
    
        axios.patch(`${Env.server}/api/homepage/updateData`, data)
        .then((res) => {
            let resp = res.data.message;
            if (resp === "successSave") {
                // setUseEffectResp(!useEffectResp);
                // closeAddProductModal();
                console.log("success save", res.data);
                GetAllData();
            }
        })
        .catch((err) => {
            console.log("Catch Error", err);
        })
    }
  };

  const publishBlogNow = (imagesArray) => {
    const data = {
        imageHowToOrder: imagesArray,
        IndusHead: industrialHeading,
        howToOrder: howToOrder,
        orderHead: howToOrderHeading
    };
    console.log("data", data);

    axios.patch(`${Env.server}/api/homepage/updateData`, data)
    .then((res) => {
        let resp = res.data.message;
        if (resp === "successSave") {
            // setUseEffectResp(!useEffectResp);
            // closeAddProductModal();
            console.log("success save", res.data);
            GetAllData();
        }
    })
    .catch((err) => {
        console.log("Catch Error", err);
    })
  };

    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <h2 className="m-3 text-center" style={{color:"#007bff"}}>Settings</h2>

                                    {/* Section One */}
                                    <div className="m-3">
                                        <h3 className="text-left">Section One</h3>
                                        <label className="text-left d-block">Industrial Heading</label>
                                        <input type="text" className="form-control" onChange={(e) => setIndustrialHeading(e.target.value)} value={industrialHeading} placeholder="Enter industrial input" />
                                    </div>

                                    {/* Section Two */}
                                    <div className="m-3">
                                        <h3 className="text-left">Section Two</h3>
                                        <label className="text-left d-block">How to Order</label>
                                        <input type="text" className="form-control mb-3" onChange={(e) => setHowToOrderHeading(e.target.value)} value={howToOrderHeading} placeholder="Enter how to order instructions" />
                                         
                                    </div>

                                    {/* Section Three */}
                                    <div className="m-3">
                                        <h3 className="text-left">Section Three</h3>
                                        <JoditEditor ref={editor2}  onChange={(newContent) => {
                                  console.log("acb===", newContent);
                                  handleChange(newContent);
                                }}  value={howToOrder} />
                                    </div>
 
                                    {/* Image Section */}
                                    <div className="m-3">
                              <div
                                onClick={handleInsideClick}
                                style={{
                                  marginTop: "60px",
                                  marginBottom: "0px",
                                  width: "200px",
                                  height: "200px",
                                  border: "1px dashed #aaa",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  position: "relative",
                                  overflow: "hidden",
                                  backgroundColor: "black",
                                }}
                              >
                                {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                <h3
                                  style={{ color: "white", marginTop: "80px" }}
                                >
                                  Choose Image
                                </h3>
                                <input
                                  {...getInputProps()}
                                  id="fileInput" // Ensure this ID is present
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <br />

                              <Grid container spacing={1}>
                                {imagesFront.map((file, index) => (
                                  <Grid item key={file.name}>
                                    <div>
                                      <img
                                        src={file.preview}
                                        alt={file.name}
                                        width="200"
                                      />
                                      <IconButton
                                        onClick={() => removeImage(index)}
                                        size="small"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </div>
                                  </Grid>
                                ))}
                              </Grid>
                            
                                    </div>

                                    {/* Submit Button */}
                                    <div className="text-center my-4">
                                        <button className="btn btn-primary" onClick={publishImage}>Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default Settings;