import React, { useState, useEffect } from "react";
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Env from "../../Environment/Env";

const Specification = () => {
  const currentPath = window.location.pathname;

  // State to manage description and specification list
  const [description, setDescription] = useState("");
  const [specificationList, setSpecificationList] = useState([
    { number: 1, dimension: "", detail: "" },
  ]);


  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    axios
    .get(`${Env.server}/api/specification/GetAll`)
    .then((res) => {
      setDescription(res.data.specifications.description);

      // Add number property to each specification based on its index
      const dataWithNumbers = res.data.specifications.data.map((spec, index) => ({
        ...spec,
        number: index + 1, // Add the number property, starting from 1
      }));

      setSpecificationList(dataWithNumbers); // Update the state with the modified data
    })
    .catch((err) => {
      console.log(err);
    });
  };

  // Handle description change and log to console
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    console.log("Description:", value); // Console log description as you type
  };

  // Handle specification input change
  const handleSpecificationChange = (index, field, value) => {
    const updatedSpecifications = [...specificationList];
    updatedSpecifications[index][field] = value;
    setSpecificationList(updatedSpecifications);
    
  };

  // Add new specification row
  const handleAddSpecification = () => {
    setSpecificationList([
      ...specificationList,
      { number: specificationList.length + 1, dimension: "", detail: "" },
    ]);
  };

  // Remove specification row
  const handleRemoveSpecification = (index) => {
    const updatedSpecifications = specificationList.filter(
      (spec, idx) => idx !== index
    );
    setSpecificationList(updatedSpecifications);
  };

  // Handle save functionality and log the result
  const handleSaveSpecifications = () => {
    const formData = {
      description,
      data: specificationList.map((spec) => ({
        dimension: spec.dimension,
        detail: spec.detail,
      })),
    };


    console.log("Saved Data:", formData);
    // You can make an API call here to save the data
    axios.post(`${Env.server}/api/specification/AddNew`, formData)
      .then((res) => {
        console.log("Saved Data:", res.data);
      })
      .catch((err) => {
        console.log("err", err)
      })
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}

          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <Box sx={{ p: 3 }}>
                    <div className="d-flex justify-content-between align-items-center m-3">
                      <h2 className="text-center flex-grow-1 text-black">
                        Specification
                      </h2>
                      <Button
                        className="btn"
                        style={{ height: "auto", width: "auto", backgroundColor: "green", color: "white" }}
                        onClick={handleSaveSpecifications}
                      >
                        Save
                      </Button>
                    </div>

                    {/* Description Field */}
                    <Box sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={handleDescriptionChange}
                        sx={{ mb: 2 }}
                      />
                    </Box>

                    {/* Dynamic Rows for Dimension, Detail, and Numbering */}
                    {specificationList.map((spec, index) => (
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        key={index}
                        sx={{ mb: 2, p: 1, borderRadius: 2 }}
                      >
                        {/* Number Field */}
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label="Number"
                            variant="outlined"
                            value={spec.number}
                            onChange={(e) =>
                              handleSpecificationChange(
                                index,
                                "number",
                                e.target.value
                              )
                            }
                            disabled
                            sx={{ px: 1 }}
                          />
                        </Grid>

                        {/* Dimension Field */}
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Dimension"
                            variant="outlined"
                            value={spec.dimension}
                            onChange={(e) =>
                              handleSpecificationChange(
                                index,
                                "dimension",
                                e.target.value
                              )
                            }
                            sx={{ px: 1 }}
                          />
                        </Grid>

                        {/* Detail Field */}
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Detail"
                            variant="outlined"
                            value={spec.detail}
                            onChange={(e) =>
                              handleSpecificationChange(
                                index,
                                "detail",
                                e.target.value
                              )
                            }
                            sx={{ px: 1 }}
                          />
                        </Grid>

                        {/* Delete and Add New Buttons - Placed in the same Grid item */}
                        <Grid item xs={2}>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <IconButton
                              color="secondary"
                              onClick={() => handleRemoveSpecification(index)}
                            >
                              <ClearIcon />
                            </IconButton>

                            {index === specificationList.length - 1 && (
                              <IconButton
                                color="primary"
                                onClick={handleAddSpecification}
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Specification;
