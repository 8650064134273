import React, { Component, useEffect, useState } from 'react';
import Linechart from './linechart';
import Nextcharts from './nextcharts';
import Morecharts from './morecharts';
import Sidebar from '../main/sidebar';
import Searchbar from '../main/navigation/searchbar';
import Header from '../main/header';
import axios from 'axios';
import Env from '../../Environment/Env';
import { useDropzone } from 'react-dropzone';
import { getAsset } from '../../utils/helper';
import "./css/sscatagory.css"
import { Grid, TextField, Chip } from '@material-ui/core';
const AddSSCatagory = () => {

    const currentPath = window.location.pathname;


    const [getAllSubCatagory, setgetAllSubCatagory] = useState([]);
    const [getSubSubCatagory, setGetSubSubCatagory] = useState([]);
    const [subSubCatagoryName, setSubSubCatagoryName] = useState("");



    const [usefectTrue, setUsefectTrue] = useState(false);
    const [subCatagoryID, setSubCatagoryID] = useState("");
    const [subCatagoryName, setSubCatagoryName] = useState("");
    const [CatagoryID, setCatagoryID] = useState("");
    const [CatagoryName, setCatagoryName] = useState("");
    const [image, setImages] = useState("");
    const [responseImages, setResponseImages] = useState([]);
    const [imagesFront, setImagesFront] = useState([]);
    const [deleteID, setDeleteID] = useState("");




    useEffect(() => {
        AllSubCatagory();
        GetSubSubCatagory();
    }, [])
    useEffect(() => {
        AllSubCatagory();
    }, [usefectTrue])
 
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState(
        [
            'Price',
            'Brand',
            'Type',
            'Color',
            'Size',
            'Rating',
            'Availability',
            'Material',
            'Features',
            'Style',
            'Discount',
            'New Arrivals',
            'Best Sellers',
            'Shipping Options',
            'Warranty',
            'Compatibility',
            'Resolution',
            'Capacity',
            'Weight',
            'Dimensions',
            'Operating System',
            'Processor',
            'RAM',
            'Storage',
            'Connectivity',
            'Battery Life',
            'Camera Resolution',
            'Display Type',
            'Refresh Rate',
            'Input Lag',
            'Response Time'
        ]
    );
    const [enteredValues, setEnteredValues] = useState({});
    const [enteredValuesArray, setEnteredValuesArray] = useState({});

    const handleOptionSelect = (event) => {
        const selectedOption = event.target.value;
        if (!selectedOptions.includes(selectedOption)) {
            setSelectedOptions([...selectedOptions, selectedOption]);
            setOptions(options.filter(option => option !== selectedOption));
            setEnteredValuesArray({ ...enteredValuesArray, [selectedOption]: [] });
        }
    };

    const handleInputChange = (event, option) => {
        setEnteredValues({ ...enteredValues, [option]: event.target.value });
    };

    const handleInputKeyPress = (event, option) => {
        if (event.key === 'Enter' && enteredValues[option].trim() !== '') {
            setEnteredValuesArray({
                ...enteredValuesArray,
                [option]: [...enteredValuesArray[option], enteredValues[option]]
            });
            setEnteredValues({ ...enteredValues, [option]: '' });
        }
    };

    const handleRemoveValue = (option, index) => {
        const updatedValuesArray = [...enteredValuesArray[option]];
        updatedValuesArray.splice(index, 1);
        setEnteredValuesArray({ ...enteredValuesArray, [option]: updatedValuesArray });
    };

    const handlePublish = () => {
        console.log('Entered values:', enteredValuesArray);
    };




 

    const handleDeleteFun = () => {
        console.log("c====>>>", deleteID)
        axios.delete(`${Env.server}/api/subsubcatagory/DeleteSubsubcatagory/${deleteID}`)
            .then((res) => {
                console.log("abc===>>>", res.data.message);
                GetSubSubCatagory();
            })
            .catch((err) => {
                console.log("err====>>>", err);
            })
    }





    const handleSelectChange5 = (event) => {

        const selectedSubCategoryId = event.target.value;
        const selectedSubCategory = getAllSubCatagory.find(data => data._id === selectedSubCategoryId);
        if (selectedSubCategory) {
            setCatagoryID(selectedSubCategory.catagoryid);
            setCatagoryName(selectedSubCategory.catagory);
            console.log("Ac=====", selectedSubCategory.catagoryid)
        } else {
            console.log("Selected subcategory not found");
        }
        setSubCatagoryID(event.target.value);
        setSubCatagoryName(event.target.options[event.target.selectedIndex].text);
        console.log(`Selected option: ${event.target.value}`);
        console.log(`Selected option: ${event.target.options[event.target.selectedIndex].text}`);
    };


    const submit = (imageFile) => {
        const data = {
            catagory: CatagoryName,
            catagoryid: CatagoryID,
            subcatagory: subCatagoryName,
            subcatagoryid: subCatagoryID,
            subsubcatagory: subSubCatagoryName,
            imageSubsubCatagory: imageFile,
            filter: enteredValuesArray
        }
        if (!data) {
            alert("Fill All Fields")
        }
        console.log("FinalData====>>>", data)

        axios.post(`${Env.server}/api/subsubcatagory/addnew`, data)
            .then((res) => {
                console.log("abc===>>", res.data.message)
                console.log("abc===>>", res.data.subsubcatagories)
                GetSubSubCatagory();
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }



    const publishImage = () => {
        const formData = new FormData();
        formData.append('imagefront', imagesFront);
        console.log("muzz1-====", imagesFront)
        console.log("muzz2-====", image)
        image.forEach((img, index) => {
            formData.append(`images`, img);
        });
        let objectNames = [];
        axios.post(`${Env.server}/api/upload/multiple`, formData)
            .then((res) => {
                let resp = res.data.file;
                for (let i = 0; i < resp.length; i++) {
                    objectNames.push("/images/" + resp[i].filename);
                }
                setResponseImages(objectNames);
                if (res.data.message === "imagesSaved") {
                    submit(objectNames);
                } else {
                    alert("error not in catch")
                }
            })
            .catch((err) => {
                console.log("err------ooo", err)
                alert("catch error");
            })
    }



    const GetSubSubCatagory = () => {
        setImages([]);
        setImagesFront([])
        axios.get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
            .then((res) => {
                console.log("abc===>>", res.data.message);
                console.log("abclll===>>", res.data.subsubcatagories);
                setGetSubSubCatagory(res.data.subsubcatagories);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }


    const AllSubCatagory = () => {
        setImages([]);
        setImagesFront([])

        axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
            .then((res) => {
                console.log("Love===>>", res.data.subcatagories);
                setgetAllSubCatagory(res.data.subcatagories);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }

    const handleInsideClick = (e) => {
        e.stopPropagation();
        const isClickable = e.target.tagName === 'DIV' || e.target.tagName === 'INPUT'; // Check if the clicked element is the drop zone or input
        if (isClickable) {
            if (e.target.tagName === 'DIV') {
                document.getElementById('fileInput').click(); // Trigger the file input click event
            }
        }
    };
    const onDrop = (acceptedFiles) => {
        setImages(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setImagesFront(acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    };
    const removeImage = (index) => {
        const updatedImagesfront = [...imagesFront];
        updatedImagesfront.splice(index, 1);
        setImagesFront(updatedImagesfront)

        const updatedImages = [...image];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
    });



    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className='d-flex justify-content-between'>
                                        <div className="card-header">
                                            <h2 className="text-center f-bold"><b>Add Sub-SubCategory Form</b></h2>
                                        </div>
                                        <button className="btn btn-primary m-4" data-toggle="modal" data-target="#exampleModalLong" style={{ height: "40px" }} >Add Sub-SubCatagory</button>
                                    </div>
                                    <div className="card-body" style={{
                                        "height": "auto"
                                    }}>

                                        <div class="container">
                                            <div class="row">
                                                {getSubSubCatagory.map((data, index) => (
                                                    <div class="col-md-3" key={index}>
                                                        <div class="card" style={{ position: 'relative' }}>
                                                            <span
                                                                className="delete-icon"
                                                                data-toggle="modal"
                                                                data-target="#exampleModalLong1"
                                                                onClick={() => setDeleteID(data._id)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    position: 'absolute',
                                                                    color: 'red',
                                                                    fontSize: '20px',
                                                                    top: '5px',
                                                                    right: '10px',
                                                                    zIndex: 2,
                                                                }}
                                                            >
                                                                &#x2716; {/* Unicode for an "X" character */}
                                                            </span>
                                                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                <img
                                                                    src={getAsset(data.imageSubsubCatagory[0])}
                                                                    class="card-img-top"
                                                                    alt="..."
                                                                    style={{ height: '50px', width: 'auto', marginRight: '10px' }}
                                                                />
                                                                <div>
                                                                    <h5 className='mt-2'>{data.subsubcatagory}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">Add Sub-SubCatagory</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className="form-group">
                                                            <label htmlFor="condition" style={{ display: 'block', textAlign: 'left' }}>Sub-Catagory Name:</label>
                                                            <select id="selectField" className='form-control' value={subCatagoryID} onChange={handleSelectChange5}>
                                                                <option value="none">Choose Sub-Catagory</option>
                                                                {getAllSubCatagory.map((data, index) => (
                                                                    <>
                                                                        <option key={index} style={{ color: "black" }} value={data._id}>{data.subcatagory}</option>
                                                                    </>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="item" style={{ display: 'block', textAlign: 'left' }}>Sub-SubCatagory Name:</label>
                                                            <input type='text' id="item" className='form-control' onChange={(e) => setSubSubCatagoryName(e.target.value)} placeholder='Type Sub Sub - Catagory Name' />
                                                        </div>
                                                        <div
                                                            onClick={handleInsideClick}
                                                            style={{
                                                                marginLeft: "20px",
                                                                width: '100px',
                                                                height: '100px',
                                                                border: '1px dashed #aaa',
                                                                borderRadius: '20px',
                                                                cursor: 'pointer',
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                backgroundColor: "black"
                                                            }}
                                                        >
                                                            {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                                            <h5 style={{ color: "white", marginTop: "30px" }}>Choose Image</h5>
                                                            <input
                                                                {...getInputProps()}
                                                                id="fileInput" // Ensure this ID is present
                                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                                            />

                                                        </div>
                                                        <br />
                                                        <center>  <div className='container' spacing={1}>
                                                            {imagesFront.map((file, index) => (
                                                                <div item key={file.name}>
                                                                    <div>
                                                                        <img src={file.preview} alt={file.name} width="300" />
                                                                        <span onClick={() => removeImage(index)} size="small">
                                                                            <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        </center> 
                                                      
                                                    
                                                      




                                                        <div className="container">
                                                            <h5 style={{ marginLeft: "-320px" }}>Choose Filter:</h5>
                                                            <div className="select-container">
                                                                <div className="select-options">
                                                                    <input
                                                                        type="text"
                                                                        className="select-input"
                                                                        value={selectedOptions.join(', ')}
                                                                        readOnly
                                                                    />
                                                                    <select
                                                                        className="hidden-select"
                                                                        onChange={handleOptionSelect}
                                                                    >
                                                                        <option value="">Select an option</option>
                                                                        {options.map(option => (
                                                                            <option key={option} value={option}>{option}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="selected-options">
                                                                <strong>Selected Options:</strong><br />
                                                                {selectedOptions.map(option => (
                                                                    <div key={option}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <input
                                                                                type="text"
                                                                                className='form-control m-3 with-border' // Add border styling
                                                                                placeholder={option}
                                                                                value={enteredValues[option] || ''}
                                                                                onChange={(event) => handleInputChange(event, option)}
                                                                                onKeyPress={(event) => handleInputKeyPress(event, option)}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            {enteredValuesArray[option] && enteredValuesArray[option].map((value, index) => (
                                                                                <div key={index} className="entered-value">{value}
                                                                                    <span className="remove-option" onClick={() => handleRemoveValue(option, index)}>&times;</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <button className="update-button" data-dismiss="modal" onClick={publishImage}>Publish</button>
                                                        </div>







                                                      
                                                    </div>
 


                                                </div>
                                            </div>
                                        </div>



                                        <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">Add Product</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <h4>Are you sure to delete?</h4>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={handleDeleteFun}  >Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default AddSSCatagory;