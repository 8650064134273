import React , {useState , useEffect} from 'react';
import axios from 'axios';
import Env from '../../Environment/Env';

const DetailAll = () => {

    const [numbers, setNumbers] = useState([]);

    useEffect(() => {
        axios.get(`${Env.server}/api/industry/AllNumber`)
            .then((res) => {
                setNumbers(res.data);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }, [])


    const orders = [
        { 'field': 'Users', 'units': 2, 'Numbers': numbers.users },
        { 'field': 'Products', 'units': 2, 'Numbers': numbers.product },
        { 'field': 'Industries', 'units': 3, 'Numbers': numbers.industries },
        { 'field': 'Label/Stickers', 'units': 3, 'Numbers': numbers.other },
        { 'field': 'Shapes & Style', 'units': 1, 'Numbers': numbers.shape },
        { 'field': 'Materials', 'units': 5, 'Numbers': numbers.material },
        { 'field': 'Quotes', 'units': 5, 'Numbers': numbers.quoteform },
    ];

    return (
        <div className="container">
            <div className="row justify-content-center">
                {orders.map((order, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-3 d-flex align-items-stretch mb-4" style={{ height: "100px",width:"100px" }}>
                        <div className="card w-100">
                            <div className="card-body text-center">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="card-text" style={{ color: "#4c84ff" }}>{order.field} : <span style={{ color: "black" }}>{order.Numbers}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DetailAll;
