import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';
import Sidebar from '../main/sidebar';
import Header from '../main/header';

const AllQuotes = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [getAllProducts, setGetAllProducts] = useState([]);
    const currentPath = window.location.pathname;

    useEffect(() => {
        getAllCarts();
    }, []);

    const getAllCarts = () => {
        axios.get(`${Env.server}/api/quoteform/getAllQuotesForm`)
            .then((res) => {
                setGetAllProducts(res.data.quoteforms);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // Handle delete confirmation
    const handleDeleteClick = (quote) => {
        setSelectedQuote(quote); // Save the quote to delete
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        if (selectedQuote) {
            // Perform delete API call
            axios
                .delete(`${Env.server}/api/quoteform/delete/${selectedQuote._id}`)
                .then((res) => {
                    console.log('Deleted successfully');
                    // Refresh the quotes after deleting
                    getAllCarts();
                    setShowDeleteModal(false);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    // Handle viewing quote details
    const handleViewClick = (quote) => {
        setSelectedQuote(quote); // Save the selected quote details
        setShowViewModal(true);
    };

    return (
        <>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}
                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className="d-flex justify-content-between m-3 align-items-center">
                                        <div className="flex-grow-1 text-center">
                                            <h2 className="mb-0">All Quotes</h2>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            {getAllProducts.map((item) => (
                                                <div key={item._id} className="col-md-3 mb-4">
                                                    <div className="card text-center" style={{ height: "100%", maxWidth: "18rem" }}>
                                                        <img
                                                            src={getAsset(item.images[0])}
                                                            className="card-img-top"
                                                            alt={item.title}
                                                            style={{ height: "200px", objectFit: "cover" }}
                                                        />
                                                        <div className="card-body">
                                                            <h5 className="card-title">{item.name}</h5>
                                                            <p>{item.message}</p>
                                                            <div className="d-flex justify-content-center">
                                                                <button
                                                                    className="btn btn-danger mx-2"
                                                                    onClick={() => handleDeleteClick(item)}
                                                                >
                                                                    Delete
                                                                </button>
                                                                <button
                                                                    className="btn btn-primary mx-2"
                                                                    onClick={() => handleViewClick(item)}
                                                                >
                                                                    View Quote
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Delete Confirmation Modal */}
                                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Delete</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Are you sure you want to delete this quote?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                Cancel
                                            </Button>
                                            <Button variant="danger" onClick={handleDeleteConfirm}>
                                                Delete
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* View Quote Details Modal */}
                                    <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
    <Modal.Header closeButton>
        <Modal.Title style={{ color: "#007bff" }}>Quote Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {selectedQuote ? (
            <div>
                {/* Flexbox layout to position text on the left and images on the right */}
                <div style={{ display: "flex", gap: "20px" }}>
                    
                    {/* Text and details on the left side */}
                    <div style={{ flex: "1" }}>
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
            {/* Row template */}
            {[
                { label: "Name", value: selectedQuote.name },
                { label: "Message", value: selectedQuote.message },
                { label: "Email", value: selectedQuote.email },
                { label: "Ph-Num", value: selectedQuote.phNum },
                { label: "Product Info", value: selectedQuote.productInfo },
                { label: "Color", value: selectedQuote.color },
                { label: "Units", value: selectedQuote.units },
                { label: "Length", value: selectedQuote.length },
                { label: "Stock", value: selectedQuote.stock },
                { label: "Width", value: selectedQuote.width }
            ].map((row, index) => (
                <tr key={index} style={{ height: "50px", borderBottom: "1px solid #ccc" }}>
                    <td style={{ padding: "10px", fontWeight: "bold", color: "black", borderRight: "1px solid #ccc" }}>
                        {row.label}:
                    </td>
                    <td style={{ padding: "10px", color: "black" }}>
                        {row.label === "Color" ? (
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <div style={{
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: row.value,
                                    border: "1px solid #ccc",
                                    marginRight: "10px"
                                }} />
                                {row.value || "N/A"}
                            </div>
                        ) : (
                            row.value || "N/A"
                        )}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>


                    {/* Images on the right side */}
                    <div 
                        style={{ 
                            flexShrink: "0", 
                            display: "grid", 
                            gridTemplateColumns: "repeat(2, 1fr)", 
                            gap: "10px",  // Gap of 10px between both rows and columns
                        }}
                    >
                        {selectedQuote.images && selectedQuote.images.length > 0 ? (
                            selectedQuote.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={getAsset(image)} // Assuming `image` is the field holding the image URL
                                    alt={selectedQuote.name}
                                    style={{ 
                                        width: "100%", 
                                        height: "200px", 
                                        borderRadius: "8px", 
                                        margin: "0",  // Ensure no extra margin is applied
                                        padding: "0", // Ensure no extra padding is applied
                                    }}
                                />
                            ))
                        ) : (
                            <p>No images available.</p>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <p>No details available.</p>
        )}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowViewModal(false)}>
            Close
        </Button>
    </Modal.Footer>
</Modal>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllQuotes;
