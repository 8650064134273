import React, { Component } from 'react';
import Sidebar from "./sidebar";
import Header from './header';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../dashboard/dashboard';
import Profile from '../pages/profile';
import Signin from '../pages/auth/signin';
import Invoice from '../pages/others/invoice';
import Errors from '../pages/others/errors';
import Sellerproducts from '../charts/Sellerproducts';
import AddProducts from '../charts/AddProducts';
import AddCatagory from '../charts/AddCatagory';
import AddSubCatagory from '../charts/AddSubCtagory';
import AddSSCatagory from '../charts/AddSSCatagory';
import Settings from '../charts/Settings';
import HeaderImages from '../charts/HeaderImages';
import Industries from '../charts/Industries';
// import Categories from '../charts/Categories';
import ShapeStyle from '../charts/ShapeStyle';
import Materials from '../charts/Materials';
import LabelOther from '../charts/LabelOther';
import AllQuotes from '../charts/AllQuotes';
import Specification from '../charts/Specification';
import PrintingMethod from '../charts/PrintingMethod';
import InkUse from '../charts/InkUse';
import Finishing from '../charts/Finishing';
import AddOnes from '../charts/AddOnes';
import AddFaqs from '../dashboard/AddFaqs';
import MetainfoHomepage from '../charts/MetainfoHomepage';
import Contentpage from '../charts/Contentpage';

class Main extends Component {

    render() {
        // const currentPath = window.location.pathname;

        return (

            // <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
            //     {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

            //     <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
            //         {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}

            <div className="content-wrapper">

                <Switch>
                    <Route exact path="/Settings" component={Settings} />
                    <Route exact path="/HeaderImages" component={HeaderImages} />
                    <Route exact path="/metainformation" component={MetainfoHomepage} />
                    <Route exact path="/contentpage" component={Contentpage} />
                    
                    <Route exact path="/Industries" component={Industries} />
                    {/* <Route exact path="/Categories" component={Categories} /> */}
                    <Route exact path="/ShapeStyle" component={ShapeStyle} />
                    <Route exact path="/Materials" component={Materials} />
                    <Route exact path="/LabelOther" component={LabelOther} />
                    <Route exact path="/AddProducts" component={AddProducts} />
                    <Route exact path="/AllQuotes" component={AllQuotes} />
                    
                    <Route exact path="/specifications" component={Specification} />
                    <Route exact path="/printingmethod" component={PrintingMethod} />
                    <Route exact path="/inkuse" component={InkUse} />
                    <Route exact path="/finishing" component={Finishing} />
                    <Route exact path="/addons" component={AddOnes} />
                    <Route exact path="/AddFaq" component={AddFaqs} />
                    

                    
                    
                    



                    <Route exact path='/' component={Dashboard} />
                    <Route exact path='/profile' component={Profile} />
                    <Route exact path='/signin' component={Signin} />
                    <Route exact path="/invoice" component={Invoice} />
                    <Route exact path="/error" component={Errors} />
                    <Route exact path="/Sellerproducts" component={Sellerproducts} />
                    

                    <Route exact path="/AddCatagory" component={AddCatagory} />
                    <Route exact path="/AddSubCatagory" component={AddSubCatagory} />
                    <Route exact path="/AddSubSubCatagory" component={AddSSCatagory} />
                </Switch>

            </div>
            //     </div >
            // </div >


        );
    }
}

export default Main;